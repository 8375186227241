.intermediate-concepts {
  display: flex;
  flex: 1 1;
  width: 1100px;
  max-height: 720px;

  &__sidebar {
    width: 335px;
    background-color: var(--main-gray);
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }

  &__content {
    width: 70%;
    height: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }

  &__list {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    min-height: 295px;
    background-color: var(--main-gray);
    display: flex;
  }

  &__item {
    width: 100%;
  }

  &__item-wrap {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    width: 100%;

    &_three {
      .intermediate-concepts__count {
        width: 20%;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    overflow: hidden;
  }

  &__name-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__count {
    color: var(--primary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding-left: 5px;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: var(--dark-gray);
  }

  &__header {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid var(--main-border-color);

    .intermediate-concepts {
      &__name {
        font-weight: bold;
        padding-left: 22px;
        font-size: 14px;
      }

      &__count {
        color: var(--primary-dark);
        font-weight: bold;
        font-size: 14px;
      }
    }

    &_three {
      .intermediate-concepts {
        &__name {
          width: 30%;
        }

        &__count {
          width: 20%;
        }
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 22px;
    padding-bottom: 20px;
  }

  &__results {
    text-align: center;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .create-set-filter {
    &__wrap {
      padding-bottom: 0;
    }

    &__title {
      padding-left: 20px;
    }
  }

  .title5 {
    padding-bottom: 5px;
    padding-left: 2px;
    font-weight: bold;
  }

  &-filter {
    margin-left: 10px;
  }

  &__limit {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    &_error {
      .intermediate-concepts__limit-text {
        color: red;
      }
    }
  }

  &__limit-text {
    display: block;
    font-size: 16px;
    padding-left: 8px;
    opacity: 0.8;
    max-width: 85%;
  }

  &__count .circle-btn,
  &__name .circle-btn {
    margin-left: 5px;
  }
}
