.tooltip {
  position: fixed;
  z-index: 10;
  border-radius: 4px;
  background-color: #ffffff;
  animation: append 0.2s ease-in-out;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0,0,0,.12);

  &__arrow {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  &__btn {
    cursor: pointer;
    display: inline;
  }
}

@keyframes append {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
