.antibodies-graphics {
  margin: 20px;

  .pie-chart {
    display: flex;
    justify-content: center;
  }
}

.gene-details-per-section__title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-light);
}

.gene-expression-antibodies-title-sub {
  padding: 15px 20px;
  font-size: 18px;

  a {
    color: var(--secondary-dark);
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}

.bar-chart.antibodies-reactivity,
.bar-chart.antibodies-application {
  display: flex;
  justify-content: center;
}

