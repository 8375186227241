.input {
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  margin: 0;
  border-radius: var(--border-radius);
  box-shadow: none;
  border: 1px solid var(--main-border-color);

  &:focus {
    box-shadow: 0 0 0 1px var(--secondary-medium);
    border-color: var(--secondary-medium);
  }
}
