.simple-from-complex-root {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-from-complex-content {
  width: 80%;
  max-width: 1000px;
  background-color: #FFFFFF;
  padding: 50px 40px;
  position: relative;
  max-height: 85%;
  display: flex;
  flex-direction: column;
}

.simple-from-complex__form {
  padding: 25px 0;
}

.simple-from-complex-content .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.simple-from-complex-content .page-title {
  padding: 0;
}

.simple-from-complex-content form {
  overflow-y: auto;
}

.spinner-container {
  text-align: center;
}

.simple-from-complex__content {
  overflow: auto;
}

.simple-from-complex__content form .form-label {
  width: 15%;
}

.center-label {
  position: relative;
  top: -12px;
}

.autocomplete_wrapper div {
  max-height: 140px !important;
}

.simple-from-complex-content .create-set-from-complex__select {
  width: 450px;
}

.simple-from-complex-content .button-row {
  margin-top: 50px;
}
