.unresolved-concepts {
  margin-bottom: 50px;

  .cr-select {
    width: 100%;
  }

  .scrollable-x-table {
    overflow-x: visible;
  }

  .ReactVirtualized__Grid {
    &.ReactVirtualized__Table__Grid {
      overflow: visible !important;
    }
  }

  .ReactVirtualized__Table__row,
  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
}