.create-set {
  display: flex;

  &_popup {
    flex: 1;
    width: 1100px;
    max-height: 780px;
  }

  &__sidebar {
    width: 335px;
    background-color: var(--main-gray);
    padding-top: 15px;
    padding-bottom: 10px;
    overflow-y: auto;
    flex-shrink: 0;
  }

  &_page {
    flex: 1;
    max-height: calc(93%);
    position: fixed;
    width: 100%;
  }

  &__sidebar-page {
    width: calc(30%);
    background-color: var(--main-gray);
    padding-top: 15px;
    padding-bottom: 10px;
    overflow-y: auto;
    flex-shrink: 0;
  }

  &__content {
    width: 70%;
    //height: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__save {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    margin-top: auto;
  }

  &__button {
    margin-left: 10px;
  }

  &__save-button {
    margin-right: 10px;
    float: right;
  }

  &__header {
    text-align: center;
    padding-bottom: 10px;
  }

  &__relation-map-icon {
    height: 23px;
    width: 28px;
    margin-top: 5px;
    margin-right: 5px;
  }

  &__title {
    font-size: 22px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  &__btn {
    cursor: pointer;
    color: var(--primary-medium);
    border: 1px solid var(--primary-medium);
    border-radius: var(--border-radius);
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    background-color: #ffffff;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-medium);
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .export-btn {
    cursor: pointer;
  }

  .export-btn--disabled {
    opacity: 0.4;
    cursor: default;
  }

  &__limit {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    &_error {
      .create-set__limit-text {
        color: red;
      }
    }
  }

  &__limit-text {
    display: block;
    font-size: 16px;
    padding-left: 8px;
    opacity: 0.8;
  }
}

.create-set-info {
  padding-top: 20px;

  &__controls {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 10px;
  }

  &__name,
  &__select,
  &__description {
    width: 100%;
    padding-bottom: 10px;
  }

  &__select {
    .select,
    .add-tag__select {
      .css-yk16xz-control,
      .css-1pahdxg-control {
        background-color: var(--main-gray);
        font-size: 14px;
        border: none;
      }

      .css-1wa3eu0-placeholder {
        font-size: 14px;
      }
    }
  }

  &__select-wrap {
    position: relative;
    width: calc(50% - 5px);
  }

  &__label {
    padding-left: 7px;
    padding-bottom: 10px;
    font-weight: 600;
    color: var(--primary-medium);
    font-family: 'NotoSans';
  }

  &__tags-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
}

.create-set-input {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--main-gray);
  background-color: var(--main-gray);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.create-set-textarea {
  width: 100%;
  height: 100px;
  border-radius: 4px;
  border: 1px solid var(--main-gray);
  background-color: var(--main-gray);
  padding: 10px;
  font-size: 14px;
  font-family: sans-serif;

  &::placeholder {
    font-family: sans-serif;
  }
}

.create-set-filter {
  height: 100%;
  overflow-y: auto;

  &__title {
    font-weight: 600;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 38px);
  }

  &__scrolled-wrap {
    overflow: auto;
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

    .create-set-filter__btn {
      width: calc(50% - 5px);
    }
  }

  &__column {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    .create-set-filter__block {
      width: calc(50% - 5px);
    }
  }

  &__block {
    padding: 10px;
    margin: 15px;
    padding-bottom: 0;
    padding-top: 0;

    input {
      width: 100%;
      height: 36px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      box-shadow: none;
    }

    &_no-padding {
      padding: 0;
      margin: 0;
      padding-bottom: 12px;
    }
  }

  &__btn {
    cursor: pointer;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--main-border-color);
    color: black;
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;

    &:hover {
      background-color: var(--main-border-color);
    }

    &_apply {
      color: var(--primary-medium);
      border: 1px solid var(--primary-medium);
      font-weight: 500;

      &:hover {
        color: #ffffff;
        background-color: var(--primary-medium);
      }
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__wrap {
    padding: 10px;
    margin: 15px;
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
  }

  &__label {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    font-size: 15px;
    color: black;
    opacity: 0.8;
    padding-left: 3px;
  }

  &__label-text {
    display: inline-block;
  }

  &__icon.first-info-icon {
    font-size: 14px;
    margin-left: 5px;
  }

  &__no-bottom {
    padding-bottom: 0;
  }
}

.source-deatails-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 180px;

  &__text {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__count {
    height: 22px;
    line-height: 22px;
    background-color: var(--primary-light);
    text-align: right;
    color: white;
    margin-left: 5px;
    padding-right: 10px;
    padding-left: 8px;
    font-size: 14px;
    border-radius: 3px;
  }
}
