.legend-wrapper {
  position: absolute;
  bottom: 15px;
  cursor: default;

  .legend__title {
    display: inline-block;
    padding-bottom: 5px;
    font-weight: 500;
    color: var(--primary-dark);
    opacity: 0.9;
  }
}

.legend {
  &__text {
    font-size: 12px;
    word-break: break-word;
  }

  &__color-block {
    width: 15px;
    height: 15px;
    display: block;
    border: 1px solid black;
    margin-right: 10px;
  }

  &__tooltip {
    max-width: 250px;
    white-space: pre-line;
  
    &.show {
      opacity: 1 !important;
    }
  }

  &__item--hover:hover {
    cursor: pointer;
  }
}

.legend-wrapper li {
  display: flex;
  padding-bottom: 5px;
}