.find-related {
  &__title {
    padding-bottom: 10px;
    text-align: center;
  }

  &__method {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 20px;
    background-color: var(--background-light-color);
  }

  &__method-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .button,
    .button-select {
      width: calc(25% - 20px);
    }
  }

  &__category-selection {
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    gap: 20px;
  }

  &__trim-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }

  &__info-text {
    line-height: 1.5;
    background-color: #f4f4f4;
    padding: 10px 50px;
    margin-bottom: 10px;
  }
}
