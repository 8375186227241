.multi-checkboxes {
  background-color: #ffffff;
  border-radius: 4px;
  padding-bottom: 10px;

  &__item {
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  &__item-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-radius: 4px;
    background-color: var(--background-light-color);
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--primary-dark);
    font-size: 14px;
    height: 100%;
  }

  &__name-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__email {
    margin-left: auto;
    color: var(--primary-dark);
    font-size: 14px;
  }

  &__color {
    width: 5px;
    height: 100%;
    background-color: var(--dark-gray);
    flex-shrink: 0;
  }

  &__link {
    flex-shrink: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 54px;
    padding-left: 18px;
    padding-right: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--main-border-color);

    .circle-btn {
      width: 28px;
      height: 28px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  &__header-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
  }

  &__count {
    color: var(--primary-dark);
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    opacity: 0.9;
  }

  .group-icon {
    path {
      stroke: var(--primary-medium);
    }

    &_active {
      path {
        stroke: var(--primary-dark);
      }
    }
  }

  &__max {
    display: block;
    margin: auto;
    color: var(--primary-dark);
    opacity: 0.1;
  }
}