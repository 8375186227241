.upload-file-types-setup {
  padding-bottom: 15px;

  &__content {
    flex: 1 1;
    max-height: 100%;
    overflow-y: auto;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__other {
    padding-left: 10px;
    padding-right: 10px;
  }
}
