.button {
  &_grey {
    color: var(--primary-medium);
    background-color: var(--background-light-color);
    border: none;
  }

  &_semi {
    color: var(--primary-medium);
    background-color: var(--main-border-color);
    border: none;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-medium)!important;
    }
  }

  &_active {
    color: #ffffff;
    background-color: var(--primary-medium);
    border: none;
  }
}