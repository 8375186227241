.search-filter-form {
  width: 220px;

  &__label {
    display: inline-block;
    margin-bottom: 5px;
    padding: 0 2px;
    font-size: 14px;
    color: var(--primary-medium);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  
    .search-filter-form__input {
      width: calc(50% - 3px);
    }
  }

  &__block {
    .sources__no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      color: var(--dark-gray);
    }
  
    .css-1wa3eu0-placeholder {
      font-size: 14px;
      color: #aaa;
    }
  
    .cr-select {
      margin-bottom: 10px;
    }
  }

  &__input {
    position: relative;

    input {
      box-shadow: none;
      border: 1px solid var(--main-border-color);
      border-radius: 3px;
      height: 38px;

      &::placeholder {
        color: #aaa;
      }
    }

    .error-text {
      position: absolute;
      right: 10px;
      bottom: calc(100% + 7px);
      background-color: #ffffff;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      border: 1px solid var(--main-border-color);
  
      &__text {
        position: relative;
        display: block;
        height: 100%;
  
        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: calc(100% + 5px);
          right: 50%;
          transform: translateX(50%);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #ffffff;
          z-index: 1;
        }
  
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: calc(100% + 5px);
          right: 50%;
          transform: translateX(50%);
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid var(--main-border-color);
        }
      }
    }
  }

  &__select {
    .Select-control {
      border-color: var(--main-border-color);
      border-radius: 3px;

      &:hover {
        box-shadow: none;
      }
    }

    &_hidden {
      display: none;
    }
  }

  &__btn {
    width: calc(50% - 5px);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
