.modal {
  position: relative;
  outline: none;
  border-radius: 10px;
  background-color: #ffffff;

  &_no-paddings {
    .modal-content {
      padding: 0;
    }
  }
}

.modal-content {
  display: flex;
  justify-content: center;
  overflow: auto;
  max-height: 90vh;
  max-width: 90vw;
  min-height: 200px;
  min-width: 400px;
  border-radius: 10px;
  padding: 10px 15px;
}

.overlay-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-close-btn {
  &.circle-btn {
    position: absolute;
    top: 0;
    left: -50px;
    background-color: black;
  }
}