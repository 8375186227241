$dots: 12;
$first-from-color:   rgb(223,199,0);
$first-to-color:     rgb(118,100,157);
$second-from-color:  rgb(130,172,189);
$second-to-color:    rgb(182,139,100);
$animation-duration: 1s;
@function animation-delay($delay){
  @return $delay * 0.15s;
}

.loader {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  div:nth-child(odd) {
    position: absolute;
  }
  
  span {
    display: inline-block;
    position: relative;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
    transform: scale(0, 0);
  }
  
  div:nth-child(odd)  {
    @for $i from 1 through $dots {
      span:nth-child(#{$i}) {
        animation: animateFirstDots $animation-duration ease-in-out infinite;
        animation-direction: alternate;
        animation-delay: animation-delay($i);
      }
    }
  }
  
  div:nth-child(even)  {
    @for $i from 1 through $dots {
      span:nth-child(#{$i}) {
        animation: animateSecondDots $animation-duration ease-in-out infinite;
        animation-direction: alternate-reverse;
        animation-delay: animation-delay($i);
      }
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
    left: 0px;
    z-index: 10;
    background-color: rgba(255,255,255,0.5);
  }
}

.loader-wrap {
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &_active {
    display: flex;
  }
}


@keyframes animateFirstDots {
  0% {
    transform: translateY(200%) scale(0.7, 0.7);
    background-color: $first-from-color;
  }
  100% {
    transform: translateY(-200%) scale(1, 1);
    background-color: $first-to-color;
  }
}

@keyframes animateSecondDots {
  0% {
    transform: translateY(200%) scale(0.7, 0.7);
    background-color: $second-from-color;
  }
  100% {
    transform: translateY(-200%) scale(1, 1);
    background-color: $second-to-color;
  }
}
