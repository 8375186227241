.short-pub-details {
  padding-top: 20px;
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: scroll;

  &__item {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__item-wrap {
    background-color: #ffffff;
    border-radius: 4px;
  }

  &__column {
    width: 50%;
  }

  &__name {
    display: block;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 1.5;
    color: var(--secondary-dark);
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--main-border-color);
  }

  &__info-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
  }

  &__subtitle {
    font-weight: 600;
  }

  &__text {
    opacity: 0.7;
  }
}
