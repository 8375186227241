.orthologs {
  &__chapter {
    margin: 20px;
    padding-bottom: 20px;
  }

  &__tooltip {
    max-width: 400px;
  }

  .place-top::before {
    width: 0 !important;
  }

  .header-section {
    justify-content: center;
  }

  &__filters {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }

  &__no-data {
    color: var(--dark-gray);
  }

  &__table {
    overflow-x: scroll;
  }

  &__table-header {
    display: flex;
    padding: 10px 0;
    border-bottom: none;
    opacity: 0.8;
    color: #000;
    border: 1px solid var(--light-dark-gray);
    background-color: var(--light-dark-gray);
  }

  &__table-header-cell {
    text-align: center;
    border-right: 1px solid #e0e0e0;
    font-weight: 500;

    &:last-child {
      border-right: none;
    }
  }

  .scrollable-x-table {
     overflow: initial;
  }

  .ReactVirtualized__Table__headerTruncatedText {
    white-space: initial;
  }
}


.ortholog-selector {
  width: 300px;
  margin-right: 20px;
}
