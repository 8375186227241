.button-group{
  margin: 5px;
  display: inline-block;
}

.button-group .btn{
  margin: 0px;
  vertical-align: middle;
}

.button-group button{
  height: 30px;
  cursor: pointer;
}

.button-group>button.btn{
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.button-group .btn-default{
  border: 1px solid #c6c6c6;
}

.button-group .btn-icon{
  width: 35px;
  height: 35px;
  color: white;
  margin: 0;
  border-radius: 0 2px 2px 0;
}

.button-group .btn-info {
  border-color: transparent;
  background: var(--secondary-dark);
}

.button-group .btn-info:hover {
  background: var(--secondary-medium);
}

.button-group .btn-danger{
  background-color: #d9534f;
  border-color: transparent;
}

.button-group .btn-danger:hover{
  background-color: #c9302c;
}
