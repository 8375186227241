.search-page-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 60px;
  width: 100%;
  height: 70px;
  z-index: 1;
  background-color: #ffffff;
  border-bottom: 1px solid var(--main-border-color);

  &__group {
    &_left {
      width: 25%;
      min-width: 265px;
      padding-left: 25px;
    }

    &_right {
      min-width: 35%;
      margin-left: auto;
    }
  }

  &__value {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__btn {
    margin-left: 5px;
    margin-right: 5px;
    border: none;
  }

  &__concepts {
    flex: 1;
    width: 100%;
    height: 100%;
    padding-left: 20px;
  }

  &__and {
    display: inline-block;
    padding-left: 5px;
    color: var(--secondary-dark);
  }

  &__concept {
    display: flex;
		align-items: center;
    flex-wrap: nowrap;
    line-height: 1.5;

		.link {
			word-break: break-all;
		}

		.and {
      padding-left: 10px;
			color: var(--secondary-dark);
		}
  }
}
