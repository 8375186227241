.gene-disease-page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;
}

.gene-disease-columns,
.gene-disease-container {
  min-height: 100%;
  min-width: 220px;
}

.gene-disease-left-column {
  background-color: var(--main-gray);
}

.gene-disease-page .concept-details-columns-sidebar {
   justify-content: center;
}

.gene-disease-columns-sidebar {
  position: fixed;
  left: 30px;
  top: 10%;
}

.gene-disease-left-column-action {
  text-decoration: underline;
  cursor: pointer;
  padding: 5px;
  color: var(--primary-medium);
  transition: 0.5s;
}

.gene-disease-left-column-action.disabled {
  cursor: default;
  color: lightgrey;
}

.gene-disease-left-column-action:not(.disabled):hover {
  color: var(--secondary-active-medium)
}

.gene-disease-header {
  position: fixed;
  height: 120px;
  top: 0;
  width: 100%;
  border-bottom: 1px solid var(--main-border-color);
  line-height: 60px;
  font-size: calc( (100vw - 1366px)/(1800 - 1366) * (29 - 20) + 20px);
  padding-left: 20px;
  padding-top: 60px;
  background-color: white;
  z-index: 2;
  font-weight: 600;
}

.gene-disease-header .icon {
  margin-left: 10px;
  font-size: 23px;
  color: #bac0be;
  cursor: pointer;
}

.gene-disease-right-column {
  display: flex;
  flex-direction: column;
}

.gene-disease-content {
  margin-top: 60px;
  flex: 1;
}

.gene-disease-intro {
  margin: 30px 50px;
}

.concept-frame {
  width: 80%;
  min-height: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.concept-frame-name {
  font-size: 24px;
  text-align: center;
}

.arrow-container {
  flex-direction: column;
  align-items: center;
}

.arrow-container .link-name {
  font-size: 18px;
  color: var(--primary-medium);
}

.arrow-container .arrow {
  width: 100%;
  text-align: right;
  position: relative;
}

.arrow-container .arrow:before {
  content: "";
  display: block;
  height: 6px;
  width: 97%;
  background-color: var(--primary-medium);
  position: absolute;
  top: 17px;
}

.arrow-container .arrow i {
  font-size: 40px;
  color: var(--primary-medium);
}

.concept-frame-details {
  padding: 10px;
}

.concept-frame-details-title {
  font-style: italic;
  font-size: 18px;
}

.gene-disease-section-title {
  border-bottom: 1px solid var(--main-border-color);
  padding: 20px;
  color: var(--primary-medium);
  font-size: 26px;
}

.differential-expression-section-title {
  border-bottom: 1px solid var(--main-border-color);
  padding: 20px;
  color: var(--primary-medium);
  font-size: 26px;
}

.gene-disease-section-body {
  position: relative;
  padding: 20px;
}

.gene-disease-section-body span a{
  color: var(--secondary-active-medium);
}

.gene-disease-section-body a.search-result-item-title {
  color: var(--secondary-dark);
}

.differential-expression-section-body {
  padding: 20px;
}

.gene-disease-title-helper-text {
  margin-top: 20px;
  margin-left: 20px;
  display: none;
}

.gene-disease-title-helper-text .simple-text {
  margin: 10px 0;
}

.gene-disease-title-helper-text--visible {
  display: block;
  top: 0;
}

.gene-disease-section-title .icon {
  font-size: 20px;
  margin-left: 10px;
  color: #bac0be;
  cursor: pointer;
}

.first-info-icon {
  font-size: 20px;
  margin-left: 10px;
  color: #bac0be;
  cursor: pointer;
}

.gene-disease-section-title-helper-text {
  display: none;
  font-size: 16px;
  color: black;
  margin-top: 10px;
}

.gene-disease-section-title-helper-text--visible {
  display: block;
}

.gene-disease-section-title-helper-text ul {
  list-style-type: square;
}

.gene-disease-intro .first-section-container {
  margin: 20px 20px 20px 0;
}

.gene-disease-page__no-data {
  padding: 20px;
}

.gene-disease-section-title-helper-text a {
  color: black;
  margin-left: 3px;
}

.gene-disease__no-data {
  justify-content: center;
  color: var(--dark-gray);
  margin: 15px 0;
}
