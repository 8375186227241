.condition-of-dysregulation-diagram {
  display: flex;
  justify-content: center;
}

.gene-detail-condition-of-dysregulation-table .ReactVirtualized__Table__headerTruncatedText{
  font-size: 14px;
}

.gene-detail-condition-of-dysregulation-table{
  margin-top: 50px;
  min-height: 100px;
  height: 100%;
}

.gene-detail-condition-of-dysregulation-table .block-center.scrollable-x-table {
  height: 100%;
}

.gene-detail-condition-of-dysregulation-table .ReactVirtualized__Table__rowColumn {
  white-space: normal;
  line-height: 30px;
}

.gene-detail-condition-of-dysregulation-table-header{
  width: 1100px;
  margin: 10px auto;
}
