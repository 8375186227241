.gene-expression-block-title{
  padding: 15px 0;
  color: var(--primary-medium);
}

.gene-expression-block-title-main{
  font-weight: bold;
  font-family: NotoSans;
  font-size: 20px;
  color: var(--primary-light);
}

.gene-expression-block-title-sub{
  font-size: 14px;
  font-family: NotoSans;
}

.gene-expression-block-diagrams{
  display: flex;
  flex-direction: column;
}

.gene-expression-block-diagram {
  flex: 1;
  padding-bottom: 40px;
}

.gene-expression-block-diagram_boxplot {
  width: 100%;
}

.gene-expression-block-diagram-title{
  color: var(--primary-medium);
  font-size: 18px;
}

.export-buttons-section-gene-disease {
  position: absolute;
  left: 88%;
  z-index: 1;
}
