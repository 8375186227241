.concept-search-form {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 470px;

  &__field {
    position: relative;
    width: 100%;

    .input {
      height: 40px;
      border: 1px solid var(--main-border-color);
      border-radius: var(--border-radius);
      box-shadow: none;
      padding-right: 80px;
    }
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 40px;
    right: 0;
    top: 0;
    border: none;
    background: transparent;
    border-left: 1px solid var(--main-border-color);
  }

  &__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    position: absolute;
    border: none;
    height: 40px;
    top: 0;
    right: 51px;
  }
}
