.dd-menu .dd-menu-items ul li:not([role="separator"]):not(.separator):not(.drop-down-menu-icon-row):not(.disabled),
.dd-menu .dd-menu-items ul li:not(.drop-down-menu-icon-row)>*,
.dd-menu .dd-menu-items ol li:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ol li>* {
  color: var(--dark-gray);
}

.dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator):not(.drop-down-menu-icon-row):not(.disabled),
.dd-menu .dd-menu-items ul li:not(.drop-down-menu-icon-row)>*:focus,
.dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ol li>*:focus {
  background-color: #eeeeee !important;
  color: var(--secondary-medium)!important;
  cursor: pointer;
}

.dd-items-left {
  border-radius: 4px;
  box-shadow: 0 5px 20px rgba(0,0,0,.1)!important;
}

.not-clickable-item {
  cursor: default;
}

.user-block {
  &__name {
    font-size: 15px;
    display: inline-block;
    padding-left: 5px;
  }
}
