.concepts-search-item {
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius);
  margin-bottom: 20px;

  &__checked {
    background-color: var(--background-light-color);
  }

  &:hover {
    background-color: var(--background-light-color);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid var(--main-border-color);
  }

  &__block {
    margin: 10px;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    color: var(--primary-dark);
  }

  &__sub-title {
    font-weight: bold;
    padding-right: 5px;
    opacity: 0.8;
  }

  &__text {
    opacity: 0.7;
    line-height: 1.4;
  }
}
