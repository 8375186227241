.upload-file-import-setup {
  padding-bottom: 5px;

  &__row {
    display: flex;
    align-items: flex-start;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
  }

  &__button,
  &__item {
    width: 33.33%;
    margin-right: 5px;
    margin-left: 5px;
  }

  &__button {
    display: flex;
    align-items: center;
    color: var(--primary-dark);
    border-color: var(--primary-dark);

    &[disabled] {
      opacity: 1;
      color: var(--primary-dark);
      border-color: var(--primary-dark);
      background-color: transparent;
    }
  }

  &__input {
    border: 1px solid #fff;
  }

  &__icon {
    padding-left: 5px;
    color: var(--secondary-dark);
  }
}
