.gene-detail-clinical-trials-table .ReactVirtualized__Table__headerTruncatedText{
  font-size: 14px;
}

.gene-detail-clinical-trials-table{
  min-height: 100px;
  height: 100%;
}

.gene-detail-clinical-trials-table .block-center.scrollable-x-table {
  height: 100%;
}

.gene-detail-clinical-trials-table .ReactVirtualized__Table__rowColumn {
  white-space: normal;
  line-height: 30px;
}

.gene-detail-clinical-trials-table-header{
  width: 1100px;
  margin: 10px auto;
}
