.copy-modal {
  padding: 20px;
  min-height: 300px;

  &__title {
    font-size: 22px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  &__form {
    width: 350px;
  }

  &__block {
    padding-top: 16px;
  }

  &__label {
    display: block;
    text-align: left;
    padding-left: 3px;
    padding-bottom: 6px;
    font-size: 15px;
    color: black;
    opacity: 0.8;
  }

  &__warning {
    padding-top: 20px;
  }

  &__warning-text {
    color: red;
  }
}
