.tabs-wrapper {
  display: flex;
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  border-bottom: 1px solid var(--main-border-color);

  .tab-item {
    min-width: 132px;
    max-width: 200px;
  }

  .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 240px;
    height: 40px;
    border: 1px solid;
    border-radius: 5px 5px 0 0;
    border-color: var(--main-border-color) var(--main-border-color) transparent;
    cursor: pointer;
    transition: 0.5s;

    &_active {
      border: 1px solid;
      border-color: var(--secondary-active-medium) var(--secondary-active-medium) transparent;
      background-color: var(--secondary-active-light);
    }
  }

  .tab-text {
    font-family: 'NotoSans';
    font-size: 16px;
    color: var(--primary-medium);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
