.venn-diagram{
  text-align: center;
  margin-right: 50px;
  #format-csv {
    display: none;
  }

  #label1, #label2, #label3,
  #label4, #label5, #label6{
    font-size: 12px;
    cursor: pointer;
    z-index: 2 !important;
  }
}

.venn-container{
  margin: 0 auto;
}

.chart-tooltip-bar__content.venn-tooltip{
  background-color: black;
  color: white;
  font-size: 10px;
  max-width:500px;
  min-width: 220px;
  padding: 5px;
  border: 1px solid white;
  border-radius: 0;
}
