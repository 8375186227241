.result-disease-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 0 70px 0;
  margin: 50px 0;
  border-radius: 10px;
  border: 2px solid var(--main-gray);

  &__title {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 15px;
    font-weight: 700;
    color: var(--primary-medium)
  }

  &__block-wrapper {
    position: relative;
    display: inline-block;
    height: 60px;

    &--disease {
      width: 600px;
    }

    &--score,
    &--rank {
      width: 150px;
    }
  }

  .result-disease-block {
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    overflow: hidden;
    border-radius: 10px !important;
    border: 2px solid var(--main-gray);
    font-family: sans-serif;
    text-align: center;
    font-size: 18px;
    color: var(--primary-medium);
    line-height: 50px;

    &__container {
      position: absolute;
      top: 2px;
      width: 100%;
      text-align: center;

      .disease-block--moving {
        animation: goaround .5s linear infinite;;
      }

      .disease-block--selected {
        animation: stop .2s ease-out forwards;
      }
    }
  }
}

.disease-block {
  padding: 0 20px;

  &__text {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@keyframes goaround {
  0% {transform: translateY(0);}
  100% {transform: translateY(-1000px);}
}

@keyframes stop {
  0% {transform: translateY(-60px)}
  50% {transform: translateY(-45px)}
  75% {transform: translateY(-10px)}
  100% {transform: translateY(0px)}
}
