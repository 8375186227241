.button-select {
  position: relative;
  min-width: 200px;

  button {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    font-weight: normal;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
  }

  &__wrap {
    display: flex;
    width: 100%;
    border: 1px solid var(--primary-medium);
    border-radius: 4px;
    height: 36px;
    background-color: #ffffff;
  }

  .button-select {
    &__main {
      flex: 1;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.4px;
      color: var(--primary-medium);
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 16px;
      padding-right: 16px;
      border-left: 1px solid var(--primary-medium);
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    background-color: #fff;
    z-index: 1;
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-dark);
    overflow: hidden;
  }

  &__option {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 1.2;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary-medium);
    cursor: pointer;

    &_active,
    &:hover {
      background-color: var(--background-light-color);
    }
  }
}
