.qr-code {
  padding: 10px 0;

  &__error {
    color: var(--primary-dark);
    text-decoration: underline;
    cursor: pointer;
  }
}

.authentication {
  &__text {
    padding: 10px 0;
  }

  &__buttons-block {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}
