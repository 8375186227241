/*Primary Chart*/

/*Nested divs for responsiveness*/
.chart-wrapper {
  max-width: 800px;  /*Overwritten by the JS*/
  min-width: 304px;
  margin-bottom: 8px;
}
.chart-wrapper .inner-wrapper {
  position: relative;
  width: 100%;
}

.chart-wrapper .inner-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.chart-wrapper text {
font-family: sans-serif;
font-size: 16px;
}

.chart-wrapper .axis path,
.chart-wrapper .axis line {
  fill: none;
  stroke: #888;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}

.chart-wrapper .y.axis .tick line {
  stroke: lightgrey;
  opacity: 0.6;
  stroke-dasharray: 2,1;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.tick text {
  cursor: pointer;
}

.beesWarmTooltip {
  position: fixed;
  text-align: left;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  pointer-events: none;
  max-width: 200px;
  min-width: 50px;
}

/*Box Plot*/
.chart-wrapper .box-plot .box {
  fill-opacity: 0.4;
  stroke-width: 2;
}
.chart-wrapper .box-plot line {
  stroke-width: 2px;
}
.chart-wrapper .box-plot circle {
  fill: white;
  stroke: black;
}

.chart-wrapper .box-plot .median {
  stroke: black;
}

.chart-wrapper .box-plot circle.median {
  /*the script makes the circles the same color as the box, you can override this in the js*/
  fill: white !important;
}

.chart-wrapper .box-plot .mean {
  stroke: white;
  stroke-dasharray: 2,1;
  stroke-width: 1px;
}

@media (max-width:500px){
  .chart-wrapper .box-plot circle {display: none;}
}

/*Violin Plot*/

.chart-wrapper .violin-plot .area {
  shape-rendering: geometricPrecision;
  opacity: 0.4;
}

.chart-wrapper .violin-plot .line {
  fill: none;
  stroke-width: 2px;
  shape-rendering: geometricPrecision;
}

/*Notch Plot*/
.chart-wrapper .notch-plot .notch {
  fill-opacity: 0.4;
  stroke-width: 2;
}

/* Point Plots*/
.chart-wrapper .points-plot .point {
  stroke: black;
  stroke-width: 1px;
}

.chart-wrapper .metrics-lines {
  stroke-width: 4px;
}

/* Non-Chart Styles for demo*/
.chart-options  {
  min-width: 200px;
  font-size: 14px;
  font-family: sans-serif;
}
.chart-options button {
  margin: 3px;
  padding: 3px;
  font-size: 12px;
}
.chart-options p {
  display: inline;
}
@media (max-width:500px){
  .chart-options p {display: block;}
}
