.find-results-for-wrapper {
  width: 300px;
}

.find-results-for {
  padding: 15px;
  border: 1px solid var(--main-border-color);
  border-radius: 3px;
  overflow: hidden;
}

.find-results-for-title {
  font-size: 18px;
  color: var(--dark-gray);
  text-decoration: underline;
}

.find-result-for-concept {
  padding: 15px 0;
}

.find-result-for-concept-name {
  text-decoration: underline;
  color: var(--secondary-dark);
  font-size: 16px;
  cursor: pointer;
}

.find-result-for-concept-st {
  color: darkgrey;
  font-size: 14px;
}

.find-result-for-concept-description {
  font-size: 14px;
}