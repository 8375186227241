.gene-disease-metabolites-table .ReactVirtualized__Table__headerTruncatedText{
  font-size: 14px;
}

.gene-disease-metabolites-table-header{
  width: 1100px;
  margin: 10px auto;
}

.conclusion-table {
  width: 100%;
  border-collapse: collapse;
}

.conclusion-table, .conclusion-table th, .conclusion-table td {
  border: 1px solid;
  padding: 10px;
}

.conclusion-table td {
  text-align: center;
}
