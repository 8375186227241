.single-cell-study {
  &__dataset-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    opacity: 0.9;

    span {
      font-weight: 500;
      color: var(--primary-dark);
    }
  }

  &__tumor-type {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }
}
