.confirm-filter-modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__select {
    min-width: 150px;
  }

  &__table {
    //margin: 15px;
    border-spacing: 15px;
  }

  .button {
    width: 100px;
  }

  td {
    padding-bottom: 20px;
  }
}
