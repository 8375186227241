.search-with-sets {
  padding-top: 80px;

  &__title {
    text-align: center;
    letter-spacing: 0.2px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &__content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  &__table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
