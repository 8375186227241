.network-analysis-modal {
  width: 600px;
  padding: 10px;

  &__title {
    text-align: center;
    padding-bottom: 10px;
  }

  &__block {
    padding-bottom: 10px;

    &_btns {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
    }
  }

  &__concepts {
    padding-top: 10px;

    .button-group {
      margin: 0;
    }
  }
}
