.relation-types-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: bold;
    padding-bottom: 20px;
  }
}