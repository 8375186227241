.stc-form-title {
  .workflows__title {
    display: block;
    text-align: center;
    margin: 10px 0;
  }
}

.stc-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__input {
    width: 400px;
    margin-right: 15px;
  }

  &__select-set-block {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
  }
}

.tissues-dnd-column {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 395px;
  background-color: var(--main-gray);
  padding-bottom: 20px;
  border-radius: 4px;
  animation: slide-up 0.4s ease;

  .input-wrapper {
    width: 180px;

    .mandatory-icon {
      left: 95%;
    }
  }

  &_mock {
    animation: pulse-light 1.2s infinite ease-in-out;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: calc(100% - 30px);
      height: 1px;
      left: 15px;
      background-color: var(--background-light-color);
    }

    &::before {
      top: 60px;
    }

    &::after {
      bottom: 20px;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid var(--main-border-color);

    .tissues-dnd-column__input {
      margin-left: auto;
    }
  }

  &__title {
    font-weight: bold;
    color: var(--primary-medium);
  }

  .form__input_small {
    line-height: 38px;
    height: 38px;
    width: 200px;
    background-color: var(--main-gray);
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
    margin-left: auto;
    box-shadow: none;
  }

  .form__input_small.input--mandatory {
    border-left: 3px solid #ff0000;
  }

  &__items {
    overflow-y: auto;
    padding-top: 5px;
    border-bottom: 1px solid var(--main-border-color);
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    transition: background-color 0.2s ease-in-out;

    &_active {
      background-color: var(--main-border-color);
    }
  }

  &__list {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-dark-gray);
    text-align: center;
  }
}

.tissues-dnd-group {
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  color: var(--dark-gray);
  min-height: 35px;

  .tissues-dnd-tissue__name {
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }
}

.tissues-dnd-tissue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 6px;
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  color: var(--dark-gray);
  font-size: 14px;
  background-color: var(--main-gray);
  transition: background-color 0.2s ease-in-out;
  min-height: 34px;

  &_active {
    background-color: var(--secondary-medium);
    border-color: var(--secondary-medium);
    color: #ffffff;
  }

  &__handle {
    cursor: grab;
    width: 10px;
    height: 20px;
    line-height: 4px;
    text-align: center;
    margin-left: auto;
  }

  &__name {
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__checkbox {
    margin-right: 5px;
    background-color: transparent;
  }

  .checkbox__wrapper span {
    cursor: pointer;
  }
}

.tissues-dnd-group-tissues {
  flex-direction: column;
}

.tissues-dnd-tissue_active {
  max-height: 350px;
}

.tissues-normal .tissues-dnd-column__list .tissues-dnd-column__list {
  max-height: 300px;
  overflow: scroll;
}

@keyframes pulse-light {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.single-target-cancer {
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 50px;

  &__table {
    width: 40%;
    background-color: white;

    &_big {
      padding-top: 127px;
    }
  }

  &__table-total {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  &__heatmap {
    width: 50%;
    z-index: 1;

    svg {
      overflow: visible;
    }

    .heatmap {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .heatmap__top-scroll {
      overflow-x: auto;
      overflow-y: hidden;

      div {
        height: 30px;
      }
    }
  }

  &__barchart {
    width: 10%;
    padding-left: 10px;
    padding-top: 15px;
    z-index: 0;

    .bar-wrap {
      .label {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .heatmap-range {
    display: flex;
    padding-top: 50px;

    .rc-slider {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.chart-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  pointer-events: none;
  max-width: 200px;
  min-width: 50px;
  z-index: 10;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #000;
}

.chart-tooltip__content {
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
}

.single-target-cancer_nodata {
  position: absolute;
  padding-bottom: 30px;
}

.sort {
  cursor: pointer;
  position: relative;

  &_down {
    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid grey;
    }
  }

  &_up {
    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid grey;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.15s ease-in;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.table {
  &__controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 65px;

    .pagination {
      margin: 0;
    }
  }

  &__btn_export {
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid var(--main-border-color);
    padding: 0;
    width: 160px;
    height: 32px;
    border-radius: 4px;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;

    .text-wrap {
      display: inline-block;
      transition: transform 0.3s ease-in;
    }

    .export-spinner {
      position: absolute !important;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &_loading {
      .text-wrap {
        transform: translateX(-10px);
      }
    }

    &:hover {
      background-color: #eeeeee;
      color: var(--secondary-medium);
    }
  }
}

.table-wrap {
  &__pagination {
    .pagination {
      padding: 0;
    }
  }
}

.loading-overlay {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  &_fixed {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    .sticky-container {
      position: unset;
      top: auto;
    }
  }
}

.sticky-container {
  position: sticky;
  top: 50%;
}

.tissues {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  justify-content: space-between;

  &__normal {
    width: calc(100% - 33.33% - 20px);
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 4px;

    .tissues__input {
      width: calc(50% - 10px);
      padding-bottom: 10px;
    }

    .tissues-dnd-column {
      width: calc(50% - 10px);
    }
  }

  &__tumor {
    width: 33.33%;
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 4px;

    .tissues__input {
      padding-bottom: 10px;
    }
  }

  &__input {
    .css-1tzh0tv-control {
      background-color: var(--main-gray);
    }
  }
}

.tissues-normal {
  display: flex;
  justify-content: space-between;
}

.single-target-cancer-table__header {
  .ReactVirtualized__Table__headerTruncatedText {
    white-space: unset;
    text-overflow: ellipsis;
  }
}

.algorithm-form__input {
  input {
    cursor: pointer;
  }
}

.target-candidates-charts .chart-wrapper {
  max-width: 1400px;
  min-width: 1200px;
}
