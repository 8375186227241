.publications {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;

  .title-overflow {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .publications-list {
    &__wrap {
      padding: 0 40px;
      overflow: hidden auto;
      height: calc(100vh - 180px);
    }
  
    &__no-data {
      display: inline-block;
      width: 100%;
      padding: 20px 0;
      text-align: center;
      color: var(--dark-gray);
    }
  }

  .publications-list-item {
    padding-top: 20px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__controller {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .search-page__pagination {
    margin: auto;

    ul {
      margin: 0;
    }
  }
}
