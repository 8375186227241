.input {
  width: 100%;
  height: 38px;
  font-size: 16px;
  padding-left: 8px;
  border: 1px solid var(--main-border-color);
}

.input:focus,
select:focus,
button:focus {
  outline: none;
}

.textarea{
  width: 100%;
  font-size: 16px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  border: none;
}

.textarea:focus{
  outline: none;
}

.input-radio{
  padding: 2px 5px;
}

button.btn {
  background-image: linear-gradient(to top,#f5f5f5,#f1f1f1);
  -webkit-user-select: none;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  color: var(--dark-gray);
  font-family: arial,sans-serif;
  font-size: 16px;
  font-family: NotoSans, sans-serif;
  min-width: 54px;
  padding: 0 10px;
  text-align: center;
  min-height: 35px;
  line-height: 24px;
  margin: 0 5px;
  cursor: pointer;
}

button.btn:hover{
  background-color: #f8f8f8;
  background-image: linear-gradient(to top,#f8f8f8,#f1f1f1);
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  color: #222;
}

button.btn[disabled],
button.btn[disabled]:hover{
  background-color: rgba(242, 242, 242, 0.5);
  background-image: none;
  border: 1px solid #f2f2f2;
  box-shadow: none;
  color: rgba(117, 117, 117, 0.5);
  cursor: default;
}

button.btn.btn-success {
  background-image: none;
  background-color: rgba(126, 70, 174, 0.9);
  color: white;
}

button.btn.btn-success[disabled],
button.btn.btn-success[disabled]:hover{
  background-color: rgba(126, 70, 174, 0.5);
  border: 1px solid #f2f2f2;
  background-image: none;
  box-shadow: none;
  color: white;
}

button.btn.btn-success:hover {
  background-color: rgba(126, 70, 174, 0.8);
  border: 1px solid rgba(126, 70, 174, 1);
}

button.btn.btn-danger{
  background-color: #d9534f;
  border-color: transparent;
  color: white;
  background-image: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

button.btn.btn-danger:hover{
  background-color: #c9302c;
}

button.btn.btn-danger[disabled],
button.btn.btn-danger[disabled]:hover{
  background-color: rgba(217, 83, 79, 0.5);
  border: 1px solid #f2f2f2;
  background-image: none;
  box-shadow: none;
  color: white;
}

button.btn.btn-info{
  background-color: #208cc2;
  border-color: transparent;
  color: white;
  background-image: none;
}

button.btn.btn-info:hover{
  background-color: #1f7bae;
}

button.btn.btn-input-addon{
  margin: 0px;
  border-radius: 0px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
}

button.btn-icon{
  color: white;
  cursor: pointer;
  border-radius: 2px;
  margin: 3px;
}

button.btn-icon.btn-icon-danger{
  background-color: #d9534f;
  border-color: transparent;
}

button.btn-icon.btn-icon-danger:hover{
  background-color: #c9302c;
}

button.btn-icon.btn-icon-success{
  background-color: #5cb85c;
  border-color: transparent;
}

button.btn-icon.btn-icon-success:hover{
  background-color: #449d44;
}

.btn-group {
  display: inline-block;
}

.btn-group label{
  padding-right: 5px;
}

.btn-group .btn {
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 0px;
}

.btn-group .btn.btn-group-last {
  margin-left: -1px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.btn-group .btn.btn-group-first {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

select.select{
  min-width: 150px;
  margin: 0 5px;
  height: 35px;
  border: none;
  font-size: 16px;
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
}
