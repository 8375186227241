.patents__title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.patents__chapter {
  margin: 20px;
  padding-bottom: 20px;
}

.patents__no-data {
  color: var(--dark-gray);
}

.indications-popup {
  display: flex;
  flex-direction: column;
  width: 70vw;
  max-height: 70vh;
  min-height: 150px;
  padding-bottom: 40px;
}

.indications-popup__title {
  height: 40px;
  line-height: 40px;
  color: var(--dark-gray);
  font-weight: bold;
  text-align: center;
  background-color: var(--main-gray);
}

.indications-popup__wrap {
  position: relative;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  column-count: 3;
  overflow-y: auto;
}

.indications-popup:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 40px;
  left: 0;
  height: 22px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.indications-popup__item {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.patent-popup {
  width: 70vw;
  height: 100%;
  max-height: 90vh;
}

.patent-popup__title {
  display: block;
  color: var(--primary-medium);
  font-size: 28px;
  padding-bottom: 20px;
}

.patent-popup__subtitle {
  padding-bottom: 15px;
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.patent-popup__id {
  display: block;
  font-size: 23px;
  font-weight: bold;
  padding-bottom: 20px;
}

.patent-popup__text {
  line-height: 1.6;
}

.patent-popup__table,
.patent-popup__chemicals {
  padding-bottom: 20px;
}

.chemical-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
}

.chemical-item__title {
  display: block;
  padding-bottom: 10px;
  color: var(--primary-medium);
}
