.relation-map-project-info {
  width: 800px;
  max-height: 720px;
  padding: 25px;

  .title2 {
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .create-set-textarea {
    height: 150px;
  }
}
