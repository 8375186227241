.relation-details {
  padding: 25px;
  height: 100%;
  max-width: 1190px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: bold;
  }

  &__name {
    flex: 1;
  }

  &__delete {
    display: block;
    height: 36px;
  }

  &__overflow {
    margin: 25px;
    overflow-y: auto;
    height: 100%;
    max-height: 400px;
  }

  .publications-list {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    &__title {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }

    .publication-common-links-wrapper {
      position: sticky;
      top: 42px;
      background-color: #fff;
      z-index: 1;
      padding-bottom: 15px;
    }
  }

  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--main-border-color);
  }

  &__input {
    width: 50%;
  }
}