.project-form {
  &__input {
    padding-bottom: 10px;

    input {
      border: 1px solid #ffffff;
    }

    .textarea {
      box-shadow: none;
    }
  }

  &__label {
    padding-bottom: 8px;
  }

  &__wrap {
    padding: 20px;
    background-color: var(--background-light-color);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}