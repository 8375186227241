.protein-structure {
  &__picture {
    min-height: 500px;
    max-height: 900px;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
      min-height: 500px;
      max-height: 900px;

      text {
        font-size: 2.5px!important;
      }
    }
  }

  &__3d-link {
    cursor: pointer;
    display: block;
    border: none;
    padding: 0;
    font-size: 16px;
    color: var(--primary-medium);
    margin-top: 20px;
    background-color: rgba(255, 243, 132, 0.5);
    &:hover {
      text-decoration: underline;
    }
  }

  &__block {
    .gene-expression-block-title-main {
      display: inline-block;
    }

    .gene-details__no-data {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}


