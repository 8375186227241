.setAnalysis{
  width: 90%;
  padding: 10px 0;
}

.set-analysis-page{
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;
}

.reports-filter{
  width: 50%;
  height: 100px;
}

.logo-analytics-part{
  height: 22px;
  color: var(--secondary-active-medium);
  font-size: 20px;
}

.analysis-title{
  text-align: center;
}

.section-header{
  padding: 0px 20px;
  background: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.section-header-field {
  width: 30%;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
  margin-left: 5px;
}

.section-header-field:focus {
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  outline: none;
}

.report-info{
  padding-left: 20px;
}

.report-name-field.input{
  margin: 20px 10px;
  width: 60%;
}

.report-title{
  padding: 20px 10px;
  font-weight: bold;
  display: inline-block;
}

.report-name-controls{
  color: var(--dark-gray);
  cursor: pointer;
}

.report-tags{
  padding: 0 10px 10px 10px;
}

.report-tag{
  background-color: var(--dark-gray);
  color: white;
  padding: 5px;
  margin-right: 10px;
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.report-tag:hover .delete-tag-button{
  display: block;
}

.report-info-tags .button-group {
  margin: 10px 5px;
}

.report-info{
  width: 50%;
}

.report-comment{
  margin: 20px;
}

.report-comment label{
  padding-right: 10px;
  font-size: 20px;
}

.report-comment textarea{
  resize: none;
  height: 200px;
  width: 600px;
  border: 1px solid var(--main-border-color);
  border-radius: 3px;
  vertical-align: top;
  outline: none;
}

.set-analysis-page .add-tag-input-container {
  width: 60%;
}

.report-info-tags .add-tag {
  width: 300px;
  padding-left: 10px;
}

.report-info-tags .add-tag-content {
  padding-left: 5px;
}

.sets-venn-diagram__container{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.set-data__concept-count {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 15px 0 0 40px;
}

.edit-title__wrapper {
  padding-left: 10px;
  padding-bottom: 10px;
}

.edit-title__wrapper span {
  display: inline-block;
  margin-left: 10px;
}

.analysis-result-non-significant-concept {
  background-color: #f09d9f;
}
