.pub-details-concepts {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-radius: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .pub-details__subtitle {
      font-size: 16px;
      padding-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
  }

  &__item-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-gray);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__name {
    color: black;
    text-decoration: none;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: var(--primary-medium);
    }
  }

  &__category {
    color: #0000009c;
  }

  &__category-btn {
    position: relative;
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  &__content {
    padding-right: 10px;
  }

  &__management-wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }

  &__btn {
    display: block;
    cursor: pointer;
    color: var(--primary-medium);
    border: 1px solid var(--primary-medium);
    border-radius: 4px;
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    background-color: #ffffff;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-medium);
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__total {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .header-section {
    justify-content: center;
  }

  .table-wrap {
    &__pagination {
      display: flex;
      justify-content: center;
    }
  }

  &__slider {
    width: 300px;
  }

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--primary-medium);
  }

  .rc-slider-dot {
    border: 1px solid #82acbd;
    margin-bottom: -0.75px;
  }

  .rc-slider-mark-text-active {
    color: #999;
  }

  .first-info-icon {
    margin-left: 5px;
  }

  .relevancy__tooltip {
    max-width: 500px;
    font-size: 14px;
    font-weight: 400;
  }
}

