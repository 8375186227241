.venn-diagram-icon {
  display: inline-block;
  width: 36px;
  height: 26px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../theme/assets/img/analytics-icon.png');
}

.venn3-diagram-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../theme/assets/img/three-overlapping-circles-v.png');
}

.management-buttons__button:not([disabled]){
    .venn-diagram-icon {
      background-image: url('../../../../theme/assets/img/analytics-icon-hover.png');
    }
}
