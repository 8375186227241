.cell-type-cluster-modal {
  padding: 30px 20px;
}

.cell-type-cluster-modal__content {
  .cell-type-cluster-content__to-csv {
    display: flex;
    justify-content: flex-end;
  }
}
