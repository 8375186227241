.edit-set-form {
  padding-top: 25px;
  padding-bottom: 30px;
  background-color: var(--background-light-color);

  &__wrap {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  textarea,
  input.input,
  .dropdown__control {
    border: 1px solid #ffffff;
    box-shadow: none;
  }

  &__file-upload {
    background-color: #ffffff;
    border-radius: var(--border-radius);
  }

  &__input {
    padding-bottom: 10px;
  }

  &__label {
    padding-bottom: 8px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }

  &__btn {
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
  }
}