.protein-structure-3d {
  width: 80vw;
  height: 80vh;
  overflow: hidden;
  border-radius: 10px;

  &__header {
    padding: 15px;
    background-color: var(--main-gray);
    position: relative;
    z-index: 1000001;

    .Select-control {
      background-color: var(--main-gray);
    }

    .cr-select {
      width: 100%;
    }
  }

  &__chart {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__popup {
    border-radius: 10px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;

    &.protein-structure-3d--expanded {
      position: fixed;
      z-index: 1000001;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &__title {
    font-weight: 500;
    cursor: pointer;
  }
}

.lm-plugin {
  .lm-layout-expanded {
      top: 68px;
    }

  .lm-plugin-content:not(.lm-layout-expanded) .lm-entity-tree-children {
    height: calc(80vh - 100px);
  }
}
