.main-page{
  .header {
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: var(--primary-medium);
    z-index: 10;
    position: absolute;
    width: 100%;
    padding-right: 40px;
    transition: background-color 0.5s;
    &.header--fixed {
      position: fixed;
    }

    &__logo {
      width: 170px;
      height: 100%;

      & img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    &__navigation {
      display: flex;
    }
  }

}

.menu-navigation {
  &__item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    padding: 10px 0px;
    margin: 0 20px;
    color: #fff;
    font-family: "NotoSans";
    font-weight: 400;

    &:after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--secondary-active-light);
      transform-origin: right;
      transform: scaleX(0);
      transition: transform .75s cubic-bezier(.19,1,.22,1);
    }

    &.menu-navigation__item--profile,
    &.menu-navigation__item--info {
      &:after {
        display: none;
      }
    }

    &--active {
      color: var(--secondary-active-light);
    }

    &:hover {
      color: var(--secondary-active-light);

      &:after {
        transform-origin: left;
        transform: scaleX(1);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
