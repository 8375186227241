.input-text-bottom-line-wrapper {
  display: flex;
  justify-content: flex-end;
}

.input-text-bottom-line-wrapper div {
  padding: 5px;
}

.input-text-bottom-line-wrapper.with-error {
  justify-content: space-between;
}

.input-text-bottom-line-wrapper.without-additional-text{
  justify-content: normal;
}

.input.input--mandatory {
  border-left: 3px solid #ff0000;
}

.input__label {
  display: inline-block;
  font-size: 14px;
  padding: 10px 0;
  font-weight: 600;
  font-family: 'NotoSans';
  color: var(--primary-medium);
}

