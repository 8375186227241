#tree-container .node {
  cursor: pointer;
}

#tree-container .overlay{
  background-color: rgba(245, 245, 245, 0.51);
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

#tree-container .node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

#tree-container .node text {
  font-size:14px;
  font-family:sans-serif;
}

#tree-container .link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

#tree-container .templink {
  fill: none;
  stroke: red;
  stroke-width: 3px;
}

#tree-container .ghostCircle.show{
  display:block;
}

#tree-container .ghostCircle, .activeDrag .ghostCircle{
  display: none;
}

#tree-container{
  text-align: center;
  display: inline-block
}

/* needed to disable scroll until diagram is focused */
#tree-container > svg {
  pointer-events: none;
}

#tree-container:focus > svg {
  pointer-events: inherit;
}
