.heatmap-select {
  width: 220px;
  height: 35px;
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: none;

  .css-yk16xz-control,
  .css-1fhf3k1-control{
    background-color: #f2f2f2;
    color: #787878;
    border: none;
    min-height: 35px;
    height: 35px;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .css-1uccc91-singleValue {
    color: #787878;
  }

  .dropdown__control {
    .dropdown__placeholder {
      font-size: 16px;
      line-height: 24px;
      font-family: NotoSans;
    }

    &--is-focused {
      height: 35px;
      min-height: 35px;
      box-sizing: border-box;
      transition: 0.5s;
    }

    &--is-disabled{
      border: none !important;
      box-shadow: none !important;
      cursor: not-allowed;
    }
  }

  .dropdown__value-container {
    font-family: NotoSans !important;
  }
}

