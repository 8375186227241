.related-concepts-card-wrapper{
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: var(--border-radius);
}

.related-concepts-margin {
  margin-bottom: 20px;
}

.related-concepts-card{
  margin-bottom: 20px;
}

.related-concepts-card .related-concepts-card-title {
  color: var(--secondary-active-medium);
  font-size: 18px;
}

.related-concepts-card .related-concept-name{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-medium);
  cursor: pointer;
}

.related-concepts-card .related-concept-count{
  float: right;
  background-color: var(--primary-light);
  min-width: 40px;
  text-align: right;
  color: white;
  padding-right: 5px;
  font-size: 15px;
  border-radius: 3px;
}

.related-concepts-card .related-concept-card-item{
  margin: 5px 0;
  height: 22px;
  line-height: 22px;
}

.related-concepts-card .related-concept-card-item-active .related-concept-name{
  color: var(--secondary-active-medium);
}

.related-concepts-card .related-concept-card-item-active .related-concept-count{
  background-color: var(--secondary-active-medium);
}

.related-concepts-card .related-concepts-card-bottom {
  font-style: italic;
  color: var(--dark-gray);
}

.related-concepts-card .related-concepts-card-bottom .more-link{
  text-align: left;
}

.related-concepts-card .related-concepts-card-bottom .get-full-list{
  text-align: right;
}

.related-concepts-card .related-concepts-card-bottom .more-link > span,
.related-concepts-card .related-concepts-card-bottom .get-full-list > span{
  cursor: pointer;
}

.related-concepts-card-mock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 211px;
  width: 100%;
  color: var(--dark-gray);
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  padding: 15px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 20px;
}

.related-concepts-card-mock_error {
  color: #f9332d;
}

.find-results-for-wrapper {
  width: 300px;
}

