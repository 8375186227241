.cr-checkbox {
  padding-bottom: 3px;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border: 1px solid #adb5bd;
      border-radius: 4px;
      box-sizing: border-box;
      z-index: 0;
    }

    &::before {
      content: '';
      display: none;
      position: absolute;
      right: 0px;
      width: 14px;
      height: 14px;
      background-image: url('../../../theme/assets/img/check-mark.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70% 80%;
      border-radius: 2px;
      z-index: 1;
    }
  }

  &__input {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .cr-checkbox__label {
      &::after {
        background-color: var(--secondary-medium);
        border: 1px solid var(--secondary-medium);
      }
  
      &::before {
        display: block;
      }
    }
  }

  &__label-value {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: black;
    opacity: 0.8;
    padding-left: 3px;
  }

  &__icon.first-info-icon {
    font-size: 14px;
    margin-left: 5px;
  }
}
