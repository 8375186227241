.antibodies-page {
  padding-top: 60px;
}

.antibodies-page__no-data {
  color: var(--dark-gray);
}

.antibodies-page__table {
  position: relative;
  margin: 20px;
  padding-bottom: 20px;
}

.antibodies-page__cell {
  word-break: break-all;
}

.antibodies-page__RiSearchLine {
  vertical-align: text-top;
  margin-left: 15px;
  cursor: pointer;
}

.antibodies-page__go-to-top {
  margin: 0 0 0 20px;
  padding-bottom: 20px;
}

.antibodies-helptext {
  margin: 20px 300px 5px 300px;
}
//.ReactVirtualized__Table__headerRow {
//  position: sticky;
//  inset-block-start: 0;
//  z-index: 1;
//}

.antibodies-tabs-wrapper {
  display: flex;
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  border-bottom: 1px solid var(--main-border-color);

  .antibodies-tab-item {
    min-width: 132px;
    max-width: 200px;
  }

  .antibodies-tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 50%;
    max-width: 50%;
    height: 40px;
    border: 1px solid;
    border-radius: 5px 5px 0 0;
    border-color: var(--main-border-color) var(--main-border-color) transparent;
    cursor: pointer;
    transition: 0.5s;

    &_active {
      border: 1px solid;
      border-color: var(--secondary-active-medium) var(--secondary-active-medium) transparent;
      background-color: var(--secondary-active-light);
    }
  }

  .antibodies-tab-text {
    font-family: 'NotoSans';
    font-size: 16px;
    color: var(--primary-medium);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
