.scatter-plot-block {
  position: relative;
  width: 100%;
}

.scatter-plot-btn {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15px;
  background-color: var(--primary-medium);
}

.scatter-plot-info {
  position: absolute;
  top: 0px;
  max-width: 500px;
  font-size: 14px;
}
