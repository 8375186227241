.interactive-cell-wrap {
  padding-top: 20px;
}

.cellWrapper {
  text-align: center;
  height: 500px;
}

.cytosol g path {
  fill: #26ff93;
  opacity: 0.1;
}

.cytosol--disabled g path {
  fill: #fafafa;
  opacity: 0.1;
}

.cytosol_ton g path {
  fill: #fff;
}

.intermediate_filaments_ton path, .nucleoplasm_ton path, .peroxisomes_ton path, .microtubule_organizing_center_ton path, .nuclear_bodies_ton path, .nucleoli_ton path, .nucleoli_fibrillar_center_ton path {
  fill: #F6F6F6;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.intermediate_filaments path {
  fill: #FCF8CE;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.intermediate_filaments--disabled path {
  fill: #e4e4e4;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.actin_filaments path {
  fill: #999999;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.actin_filaments--disabled path {
  fill: #e0e0e0;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.focal_adhesion_sites_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.focal_adhesion_sites path {
  fill: #6F6571;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.focal_adhesion_sites--disabled path {
  fill: #dbdbdb;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubule_organizing_center path {
  fill: #EFC16E;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubule_organizing_center--disabled path {
  fill: #c7c7c7;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.centrosome path {
  fill: #CD2632;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.centrosome--disabled path {
  fill: #c9c9c9;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubules_ton g path, .lysosomes_ton path, .actin_filaments_ton path, .endosomes_ton path, .golgi_apparatus_ton path, .nuclear_speckles_ton path, .rods_and_rings_ton path {
  fill: #EDEDED;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubules_ton--disabled g path, .lysosomes_ton--disabled path, .actin_filaments_ton--disabled path, .endosomes_ton--disabled path, .golgi_apparatus_ton--disabled path, .nuclear_speckles_ton--disabled path, .rods_and_rings_ton--disabled path {
  fill: #f1f1f1;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubules g path {
  fill: #E98080;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubules--disabled g path {
  fill: #e7e7e7;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubule_ends path {
  fill: #B7515C;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubule_ends--disabled path {
  fill: #dddddd;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.secreted_proteins path, .lipid_droplets path  {
  fill: #9F3253;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.secreted_proteins--disabled path, .lipid_droplets--disabled path  {
  fill: #cccccc;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.lysosomes path {
  fill: #D06986;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.lysosomes--disabled path {
  fill: #cfcfcf;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.peroxisomes path {
  fill: #E583A2;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.peroxisomes--disabled path {
  fill: #e0e0e0;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.endosomes path {
  fill: #F5B4CF;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.endosomes--disabled path {
  fill: #cccccc;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.endoplasmic_reticulum_ton g path {
  fill: #EDEDED;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.golgi_apparatus path {
  fill: #D99EBE;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.golgi_apparatus--disabled path {
  fill: #e0e0e0;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoplasm path {
  fill: #8AC0E2;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoplasm--disabled path {
  fill: #ebebeb;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_membrane path {
  fill: #7D64A1;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_membrane--disabled path {
  fill: #d6d6d6;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_bodies path {
  fill: #B6D9E8;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_bodies--disabled path {
  fill: #ececec;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_speckles path {
  fill: #60A2C4;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_speckles--disabled path {
  fill: #d3d3d3;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoli path {
  fill: #CEEAF9;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoli--disabled path {
  fill: #c0c0c0;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoli_fibrillar_center path {
  fill: #7BBCD6;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nucleoli_fibrillar_center--disabled path {
  fill: #ececec;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.rods_and_rings path {
  fill: #F49739;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.rods_and_rings--disabled path {
  fill: rgb(212, 212, 212);
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.mitochondria_ton path {
  fill: #F6F6F6;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.mitochondria path {
  fill: #E7C8CD;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.mitochondria--disabled path {
  fill: #e7e7e7;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.anotherMitohondria {
  fill: #A55978 !important;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.anotherMitohondria--disabled {
  fill: #d3d3d3 !important;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.plasma_membrane_ton g path {
  fill: #FFFFFF;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.plasma_membrane_ton path {
  fill: #EDEDED;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.plasma_membrane g path {
  fill: #FAE4EC;
}

.plasma_membrane--disabled g path {
  fill: #f3f3f3;
}

.plasma_membrane path {
  fill: #C02E66;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.plasma_membrane--disabled path {
  fill: #dbdbdb;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.centrosome_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.microtubule_ends_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.secreted_proteins_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.lipid_droplets_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.endoplasmic_reticulum g path {
  fill: #8F83BD;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.endoplasmic_reticulum--disabled g path {
  fill: #cfcfcf;
  stroke: #000000;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}

.nuclear_membrane_ton path {
  fill: #DADADA;
  stroke: #9D9D9C;
  stroke-width: 0.15;
  stroke-miterlimit: 10;
}
