.portal-block {
  display: flex;

  &__title {
    color: white;
    text-align: center;
    padding-bottom: 15px;
  }
}

.portal-block-rcorners {
  border-radius: 10px;
  border: 2px solid white;
  padding: 20px;
  margin: 20px;

  &__oncology {
    width: 380px;
    //height: 350px;
  }

  &__markers {
    width: 380px;
    height: 280px;
  }

  &__experimental {
    width: 380px;
    //height: 350px;
  }

}

.portal-button-wrapper {
  text-align: center;
}

.portal-button {
  width: 320px;
  margin: 5px;
  justify-content: left;

  &[disabled] {
    &:hover,
    &:hover i,
    &:hover svg {
      cursor: not-allowed;
    }
  }

  &[disabled],
  &--disabled {
    color: black!important;
  }

  &:hover:not([disabled]) {
    //color: whitesmoke;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .heatmap-icon polygon,
  .heatmap-icon g {
    fill: #787878;
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    margin-right: 10px;
  }

  .rank-target-icon {
    width: 19px;
    height: 19px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .access-marker-icon {
    width: 23px;
    height: 23px;
    margin-top: 3px;
    margin-left: 3px;
  }

  svg, i {
    margin-right: 8px;
  }

  &:not([disabled]) {
    svg,
    .heatmap-icon polygon,
    .heatmap-icon g {
      fill: var(--primary-medium);
    }
  }
}
