.internal-links {
  &__list {
    display: block;
    margin: 0;
    padding: 20px;
  }

  &__item {
    list-style-type: none;
    line-height: 25px;
  }

  &__no-data {
    padding: 20px;
  }
}