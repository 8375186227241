.search-publications-manage {
  display: block;
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: var(--border-radius);

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    width: calc(50% - 5px);
  }
}
