.upload-file {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;

  &__container {
    position: relative;
    margin-top: 20px;
    padding: 15px;
    padding-bottom: 20px;
    border-radius: var(--border-radius);
    border: 2px dashed var(--primary-medium);
    text-align: center;
  }

  &__btn {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &_disabled {
      pointer-events: none;
    }
  }

  &__label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 28px;
    color: var(--dark-gray);
    border: 1px solid var(--dark-gray);
    border-radius: var(--border-radius);
    background-color: transparent;
    padding: 5px 20px;
    transition: 0.5s;
    z-index: 1;

    &:hover {
      border: 1px solid var(--primary-dark);
      color: var(--primary-dark);
    }
  }

  &__icon {
    color: var(--primary-medium);
  }

  &__title {
    font-weight: 500;
    color: var(--primary-medium);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.7;
    padding-bottom: 10px;
  }
}

.upload-file-accordion {
  background-color: var(--background-light-color);

  .accordion-item__title {
    height: 40px;
    display: flex;
    font-weight: 500;
    color: var(--primary-dark);
    padding-top: 0px;
    padding-bottom: 0px;
  }
}