.search-page-start-portal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40vh;
  background-color: #4b3f63;

  &__logo {
    display: block;
    height: 100px;
    width: 250px;
    margin-right: 0;
  }

  .search-form {
    width: 500px;
  }
}
