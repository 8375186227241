.cr-select {
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-b8ldur-Input,
  .css-1wa3eu0-placeholder {
    font-family: sans-serif;
  }

  .css-1hwfws3 {
    padding-left: 8px;
  }
}
