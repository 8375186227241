.similar-publications {
  background-color: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 25px;

    .circle-btn {
      width: 40px;
      height: 40px;
      background-color: #ffffff;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-right: auto;
  }

  &__select {
    width: 150px;
    height: 40px;
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: none;
    flex-shrink: 0;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-gray);
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
