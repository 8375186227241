.concept-to-concept {
  padding-top: 60px;
  text-align: center;
}

.concept-to-concept-title {
  color: var(--primary-medium);
  font-size: 30px;
  padding: 15px 0 15px 35px;
  text-align: left;
}

.concept-to-concept-separator{
  height: 2px;
  background-color: var(--main-border-color);
}

.concept-to-concept-body {
  display: flex;
}

.concept-to-concept-info {
  width: 20%;
  padding-top: 40px;
  text-align: center;
}

.concept-to-concept-chart {
  width: 70%;
  text-align: center;
}
