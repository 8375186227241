.zoom {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__btn {
    cursor: zoom-in;
  }

  &__popup {
    margin: 0;
    padding: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;

    .modal-content {
      width: 100vw;
      height: 100vh;
      max-width: unset;
      max-height: unset;
      padding: 0;
      padding-right: 12px;
    }
  }

  &__content {
    width: calc(100% - 80px);
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.zoom-controls {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  height: 100%;
  border-left: 1px solid var(--background-light-color);
  padding-top: 10px;
  padding-bottom: calc(50vh - 70px);
  background-color: #ffffff;
  z-index: 1;

  &__btn {
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: var(--background-light-color);
    transition: transform 0.1s ease-in;
    margin-top: 10px;
    margin-bottom: 10px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -10px;
      width: 20px;
      height: 2px;
      background-color: var(--primary-medium);;
    }

    &:hover {
      transform: scale(0.92);
    }

    &_close {
      margin-bottom: auto;

      &::after {
        transform: rotate(45deg);
      }
  
      &::before {
        transform: rotate(135deg);
      }
    }

    &_plus {
      &::after {
        transform: rotate(90deg);
      }
    }

    &_minus {
      &::after {
        display: none;
      }
    }
  }
}
