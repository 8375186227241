.stacked-box-plot-wrapper {
  position: relative;
  height: 100%;
}

.stacked-box-plot {
  width: 100%;
  overflow: auto;
  position: relative;
}

.doubleboxplot-controls__radio {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 30px;
}
