.ranking-result-top-container{
  margin: 0 auto;
  min-width: 1250px;
}

.setAnalysisRankingResult{
  min-height: 790px;
}

.setAnalysisRankingResult .ReactVirtualized__Table__headerTruncatedText{
  display: inline;
  white-space: normal;
}

.setAnalysisRankingResult .ReactVirtualized__Table__headerRow{
  overflow: visible !important;
}

.header-section {
  justify-content: center;
}

.ranking-result-filter-button{
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ranking-result-filter-active {
  color: red;
}
