.search-page {
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  &__wrap {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--main-border-color);
  }

  &__form {
    width: calc(100% - 570px);
    margin-left: 245px;
  }

  &__main-content {
    position: relative;
    display: flex;
    margin: auto;
    max-width: 1420px;
    padding-top: 40px;
  }

  &__main {
    display: flex;
    width: 100%;
    margin-left: 245px;
  }

  &__content {
    width: 100%;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    min-width: 300px;
    margin-left: 25px;
  }

  &__filters-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    margin-right: 25px;
  }

  &__recommendation_link {
    &:hover {
      text-decoration: underline;
    }
  }

  &__recommendation_block {
    cursor: pointer;
    color: var(--secondary-dark);
    padding-bottom: 10px;
    text-decoration: none;

    a {
      text-decoration: none;
      color: var(--secondary-dark);
    }
  }

  &__link-block {
    @extend .search-page__recommendation_link;
    @extend .search-page__recommendation_block;
  }

  &__small-padding {
    padding-bottom: 1px;
  }

  &__block {
    padding-bottom: 10px;
  }

  &__link {
    cursor: pointer;
    color: var(--secondary-dark);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__no-results {
    color: var(--dark-gray);
    padding-top: 20px;
    text-align: center;
  }

  &__total {
    position: absolute;
    top: 10px;
    color: var(--dark-gray);
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  &__subtitle {
    display: block;
    font-size: 18px;
    border-bottom: 1px solid var(--main-border-color);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    color: var(--secondary-active-medium);
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    width: 100%;
    height: 105px;
  }

  .loader-wrap {
    padding-bottom: 100px;
  }

  &__management-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}
