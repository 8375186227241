.violin-chart {
  position: relative;
}

.chart-tooltip--popup {
  z-index: 10;
}

.grouped-violin-chart__wrap {
  width: 100%;
  overflow: auto;
  position: relative;
}
