:root{
  --main-border-color: #ddd;
  --background-light-color: #eeeef0;
  //New gray colors
  --main-gray: #eeeef0;
  --dark-gray: #9a96a2;
  --light-dark-gray: #cccad1;
  //Purple color - Primary
  --primary-dark: #2C243E;
  --primary-medium: #4b3f63;
  --primary-light: #76649d;
  //Blue color - Secondary
  --secondary-dark: #2b5566;
  --secondary-medium: #82acbd;
  --secondary-light: #cbe4ee;
  //Yellow color - Primary active
  --active-dark: #dfc700;
  --active-medium: #f1e04f;
  --active-light: #fff384;
  //Beidge color - Secondary active
  --secondary-active-dark: #593d22;
  --secondary-active-medium: #b68b64;
  --secondary-active-light: #ffddbd;

  --border-radius: 6px;
}
