.concept-card-mock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  color: var(--dark-gray);
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  padding: 15px;
  text-align: center;
  line-height: 1.5;

  &.concept-card-mock--error{
    color: #f9332d;
  }
}
