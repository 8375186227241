.concept-card {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: var(--border-radius);

  &__semantic-info {
    color: darkgray;
    font-size: 15px;
  }

  &__definition {
    font-size: 15px;
    padding: 8px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 0.8;
  }

  &__synonyms {
    display: flex;
    flex-wrap: wrap;
    opacity: 0.8;
  }

  &__synonym {
    padding-right: 5px;
    word-break: break-word;
  }
}