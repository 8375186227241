.set-result-page-add-concepts {
  position: absolute;
  border: 1px solid var(--main-border-color);
  background-color: white;
  z-index: 100;
  top: 20px;
  right: 100px;
  left: 100px;
  bottom: -39px;
  display: flex;
  flex-direction: column;
}
.set-result-page-add-concepts-header{
  height: 40px;
  line-height: 40px;
  background-color: var(--main-gray);
  border-bottom: 1px solid var(--main-border-color);
}
.set-result-page-add-concepts-header-title{
  padding-left: 20px;
  font-weight: bold;
}
.set-result-page-add-concepts-header-control{
  float: right;
  padding-right: 20px;
}
.set-result-page-add-concepts-header-control > i{
  cursor: pointer;
}

.set-result-page-add-concepts-filters{
  height: 350px;
  border-bottom: 1px solid var(--main-border-color);
}

.set-result-page-add-concepts-filters > div{
  height: 100%;
}

.set-result-page-add-concepts-filter-category,
.set-result-page-add-concepts-filter-taxonomy{
  border-right: 1px solid var(--main-border-color);
}

.set-result-page-add-concepts-filter-category,
.set-result-page-add-concepts-filter-taxonomy,
.set-result-page-add-concepts-filter-relation{
  height: 100%;
}

.set-result-page-add-concepts-filter-title {
  border-bottom: 1px solid var(--main-border-color);
  height: 35px;
  line-height: 35px;
  padding-left: 20px;
  font-weight: bold;
}

.filter-item .filter-item-header > div{
  display: inline-block;
  vertical-align: middle;
}

.filter-item-header{
  height: 30px;
  vertical-align: middle;
  line-height: 30px;
}

.filter-item{
  border-bottom: 1px solid var(--main-border-color);
}

.filter-item-color-container{
  width: 30px;
  vertical-align: middle;
}

.filter-item-color-icon{
  width: 15px;
  height: 15px;
  background-color: #1f7bae;
  margin: 0 auto;
}

.set-result-page-add-concepts-filter-category-body,
.set-result-page-add-concepts-filter-taxonomy-body,
.set-result-page-add-concepts-filter-relation-body{
  height: 315px;
  overflow: auto;
}

.filter-category-amount,
.filter-category-switcher,
.filter-taxonomy-amount,
.filter-relation-amount,
.filter-relation-switcher{
  float: right;
}

.filter-category-switcher,
.filter-relation-switcher,
.filter-taxonomy-amount{
  padding: 0 10px;
}

.filter-category-switcher,
.filter-relation-switcher{
  cursor: pointer;
}

.filter-category-amount,
.filter-relation-amount{
  padding: 0 5px;
}

.filter-category-switcher,
.filter-relation-switcher{
  font-size: 25px;
}

.taxonomy-checkbox,
.relation-checkbox,
.category-checkbox,
.related-concept-checkbox,
.result-header-control{
  margin: 0 10px;
}

.taxonomy-checkbox > input,
.relation-checkbox > input,
.category-checkbox > input{
  vertical-align: middle;
}

.filter-relation-predicate > div,
.filter-category-type > div{
  display: inline-block;
}

.filter-relation-predicate{
  height: 25px;
  line-height: 25px;
}

.filter-relation-predicate-sign{
  width: 40px;
  text-align: center;
}

.filter-relation-predicate-name{
  /*padding-left: 40px;*/
}

.filter-relation-predicate-count,
.filter-category-type-count{
  float: right;
  padding-right: 41px;
}

.filter-category-type-checkbox{
  margin: 0 5px 0 45px;
}

.set-result-page-add-concepts-results-header{
  display: flex;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid var(--main-border-color);
  box-sizing: content-box;
}

.add-related-concept-search-button {
  margin-left: auto;
  margin-right: 5rem;
}

.add-related-concept-search-button button {
  height: 25px;
  width: 100px;
}

.set-result-page-add-concepts-results-header > div{
  display: inline-block;
}

.add-related-concepts-sorting-section i{
  display: block;
  position: relative;
  color: #b9b9b9;
}

.add-related-concepts-sorting-section i.active{
  color: black;
}

.add-related-concepts-sorting-section i.fa-sort-asc{
  top: 3px;
}

.add-related-concepts-sorting-section i.fa-sort-desc{
  bottom: 3px;
}

.add-related-concepts-sorting-section{
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.result-header-title{
  padding: 0 10px 0 5px;
  font-weight: bold;
}
.add-related-concepts-sorting-search > input {
  height: 25px;
}
.add-related-concepts-sorting-search {
  padding-left: 10px;
}
.set-result-page-add-concepts-results-bottom {
  position: absolute;
  height: 40px;
  width: 100%;
  bottom: 0;
  text-align: center;
  border-top: 1px solid var(--main-border-color);
  background-color: var(--main-gray);
}

.set-result-page-add-concepts-results-bottom > div {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  background-color: white;
  border-right: 1px solid var(--main-border-color);
  border-left: 1px solid var(--main-border-color);
  padding: 0 10px;
}

.set-result-page-add-concepts-results-bottom > div.disabled {
  cursor: default;
  color: #d2d2d2;
}

.set-result-page-add-concepts-results {
  flex: 1;
  margin-bottom: 40px;
  overflow: auto;
}

.related-concept > div {
  display: inline-block;
}

.related-concept {
  border-bottom: 1px solid var(--main-border-color);
  height: 30px;
  vertical-align: middle;
  line-height: 30px;
}

.related-concept-checkbox > input {
  vertical-align: middle;
}

.related-concepts-no-items {
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: var(--light-dark-gray);
}

.filter-title {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter-title.filter-title-category{
  max-width: 165px;
}

@media (min-width: 1250px) {
  .filter-title {
    max-width: 200px;
  }
  .filter-title.filter-title-category{
    max-width: 185px;
  }
}

@media (min-width: 1401px) {
  .filter-title {
    max-width: 300px;
  }
  .filter-title.filter-title-category{
    max-width: 285px;
  }
}

.filter-category-type-name{
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter-category-type>div,
.filter-category-type input{
  vertical-align: middle;
}

.filter-relation-predicate-control{
  margin: 0 5px 0 45px
}
