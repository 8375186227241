.accordion-item {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: var(--border-radius);

  &__title {
    cursor: pointer;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.3px;
    min-height: 40px;
  }

  &__content {
    max-height: 0;
    overflow: hidden;
  }

  &__title-wrap {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .circle-btn {
    width: 26px;
    height: 26px;
    margin-left: auto;
  }
}
