.select-project-modal {
  padding: 20px;
  min-height: 270px;

  &__title {
    font-size: 22px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  &__form {
    width: 350px;
  }

  &__block {
    padding-top: 25px;
    padding-bottom: 35px;
  }

  &__label {
    display: block;
    text-align: left;
    padding-left: 3px;
    padding-bottom: 6px;
    font-size: 15px;
    color: black;
    opacity: 0.8;
  }

  &__controls {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    overflow: visible;
  }
}
