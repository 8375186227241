.concepts-search-rightbar {
  display: flex;
  flex-direction: column;
  width: 20vw;
  padding-top: 15px;
  background-color: var(--background-light-color);

  &__btn {
    margin: 20px;
    //width: 280px;
  }

  &__btn-run {
    left: 50%;
    position: absolute;
    bottom: 15px;
    width: 200px;
  }

  &__title {
    padding-bottom: 10px;
    padding-left: 15px;
  }

}

.hide {
  display: none;
}
