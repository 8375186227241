.relation-map-header {
  background-color: #ffffff;
  z-index: 1;

  .button {
    margin-right: 5px;
    margin-left: 5px;
    border: none;
    background-color: var(--main-gray);
    flex-shrink: 0;
    color: var(--primary-medium);
  }
}

.relation-map-add-concept {
  width: 100%;
  max-width: 470px;
  margin-right: auto;

  .css-yk16xz-control,
  .css-1fhf3k1-control{
    background-color: var(--main-gray);
    color: var(--primary-medium);
    min-height: 35px;
    height: 35px;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .css-1uccc91-singleValue {
    color: var(--primary-medium);
  }

  .css-1wa3eu0-placeholder {
    color: var(--primary-medium);
  }

  .css-tlfecz-indicatorContainer {
    color: var(--primary-medium);
  }

  .dropdown__control {
    .dropdown__placeholder {
      font-size: 16px;
      line-height: 24px;
      font-family: NotoSans;
    }

    &--is-focused {
      height: 35px;
      min-height: 35px;
      box-sizing: border-box;
      transition: 0.5s;
    }

    &--is-disabled{
      border: none !important;
      box-shadow: none !important;
      cursor: not-allowed;
    }
  }

  .dropdown__value-container {
    font-family: NotoSans !important;
  }
}

