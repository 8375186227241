.colors-and-shapes-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;

  &__title {
    font-weight: bold;
    padding-bottom: 20px;
  }

  &__select {
    margin-bottom: 10px;
    width: 400px;
  }

  .sketch-picker {
    border-radius: 0px !important;
    box-shadow: none !important;
    border-bottom: 1px solid rgb(238, 238, 238);
  }

  &__btn {
    margin-top: 15px;
  }
}
