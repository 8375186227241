.single-cell-study {
  .select-gene {
    width: 300px;
    padding: 0px;
  }

  &__charts,
  &__titles-block {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 50% 50%;
  }

  &__no-results {
     justify-content: center;
  }

  .legend-wrapper {
    position: initial;
    margin-top: 50px;
    padding-left: 0;
    width: 100%;
    height: 100%;
    max-height: 540px;
    overflow-x: auto;

    .legend__color-block {
      flex-shrink: 0;
    }
  }
}

.single-cell-chart-wrapper {
  &--flex-end {
    display: flex;
    align-items: flex-end;
  }
}

.single-cell-chart {
  min-width: 650px;
  min-height: 650px;
  width: 100%;
  display: flex;
}

.scatter-plot-block--gene-type,
.single-cell-study-violin-chart {
  .bar-chart-controls {
    align-items: flex-start;
  }
}

.single-cell-study-violin-chart {
  & .bar-chart-controls {
    padding-right: 140px;
  }

  .violin-chart__wrap {
    max-width: 700px;
  }
}

.single-cell-study-violins-options {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    color: var(--primary-dark);
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  &__tooltip {
    max-width: 250px;
    white-space: pre-line;
  }
}

.scatter-plot-block--gene-type {
  .bar-chart-controls {
    padding-right: 100px;
  }
}

.scatter-plot-legend {
  padding-top: 50px;
}

.single-cell-study-dataset {
  &__filters {
    padding-bottom: 20px;
  }
}

