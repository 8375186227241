.projects-modal {
  width: 90%;
  height: 95%;

  &__title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}
