#xaxis .domain {
  fill:none;
  stroke:#000;
}
#xaxis text, #yaxis text {
  font-size: 12px;
}

.tick text {
  font-size: 12px;
}

.tick line {
  stroke: black;
}

.grid-vertical-line{
  display: none;
}

.expression-tooltip{
  padding: 5px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid black;
  color: black;
  z-index: 20;
  font-size: 12px;
}
