.concepts-search-content {
  display: flex;
  width: calc(100% - 360px);
  flex-direction: column;
  padding: 15px;
  padding-left: 20px;

  &__3blocks {
    width: 50%;
  }

  &__title {
    text-align: center;
    margin: 0;
  }

  &__input {
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 15px;

    .input {
      background-color: var(--background-light-color);
      border: none;
    }
  }

  &__wrap {
    overflow: auto;
    flex: 1;
    margin-bottom: 15px;
    padding-right: 15px;
  }

  &__controls {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__suggestions {
    display: inline-flex;
    flex-wrap: wrap;
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__suggestion {
    padding-right: 10px;

    &:first-of-type {
      padding-left: 10px;
    }
  }
}
