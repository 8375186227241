.set-result-page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.set-result-page ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.set-result-page ::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.set-result-page ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #cbcbcb;
}

.set-result-page-content {
  flex: 1;
  position: relative;
}

.set-result-page-content .header-section .checkbox__wrapper {
  margin: auto;
}

.set-result-page-title {
  max-width: 1870px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 10px;
}

.set-result-table-container {
  position: absolute;
  bottom: 120px;
  top: 50px;
  width: 100%;
}

.set-result-table-container-padding {
  padding: 0 25px;
}

.set-result-relation-map-action{
  height: 35px;
  margin-left: 5px;
}

.set-result-relation-map-action div.relation-map-icon{
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../theme/assets/img/intermediate-relation-icon.png');
}

.set-result-relation-map-action>button.btn {
  border: 1px solid #ddd;
  background-image: linear-gradient(to top,#f5f5f5,#f1f1f1);
}

.set-result-relation-map-action>button.btn:hover {
  background-color: #f8f8f8;
  background-image: linear-gradient(to top,#f8f8f8,#f1f1f1);
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.set-result-table-container .ReactVirtualized__Table__rowColumn:not(:last-child) {
  border-right: none;
}

.set-result-table-container .ReactVirtualized__Table__headerColumn{
  height: 100%;
}

.set-result-table-container .ReactVirtualized__Table__headerColumn:not(:last-child){
  border-right: 1px solid var(--main-border-color);
}

.set-result-table-container .ReactVirtualized__Table__headerColumn:first-of-type{
  margin-left: 5px;
  padding-top: 20px;
}

.set-result-table-container .ReactVirtualized__Table__headerTruncatedText{
  height: 100%;
  width: 100%;
  padding-top: 20px;
}

.set-result-table-container .set-result-custom-header{
  text-align: left;
  padding-top: 20px;
  padding-left: 16px;
}

.set-result-page-title button.btn{
  margin: 0;
}

.set-result-custom-header-button{
  display: flex;
  align-items: center;
}

.set-result-page-pagination{
  max-width: 1870px;
  margin: 0 auto;
  height: 60px;
  text-align: center;
}

/* For drop down menu */
.set-result-table-container .ReactVirtualized__Table__headerRow{
  overflow: visible!important;
}

.set-result-custom-header.set-result-custom-header-dropdown{
  padding: 0;
}

.set-result-custom-header .dropdown-menu-component>button.btn{
  margin: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.set-result-custom-header .dropdown-menu-component>button.btn:hover{
  box-shadow: none;
  border: none;
}

.set-result-custom-header .dropdown-menu-component>button.btn>i{
  float: right;
  font-size: 20px;
}

.set-result-custom-header .dropdown-menu-component .dd-menu-items{
  width: 100%;
  margin: 0;
}

.set-result-custom-header .dropdown-menu-component .dd-menu-items ul{
  background-color: var(--main-gray);
}

.set-result-custom-header .dropdown-menu-component .dd-menu-items ul.dd-items-left>li:first-child{
  border-bottom: 1px solid var(--main-border-color);
}

.set-result-custom-header .dropdown-menu-component .dd-menu-items ul.dd-items-left>li:last-child{
  border-top: 1px solid var(--main-border-color);
}

.set-result-custom-header .dd-menu.dd-menu-left .dd-menu-items .nested-reverse>span {
  padding-left: 0.1em;
}

.set-result-custom-header .dd-menu.dd-menu-left .dd-menu-items .nested-reverse>span ul>li:not(:last-child) {
  border-bottom: 1px solid var(--main-border-color);
}

.set-result-custom-header .dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator):not(.drop-down-menu-icon-row),
.set-result-custom-header .dd-menu .dd-menu-items ul li:not(.drop-down-menu-icon-row)>*:focus,
.set-result-custom-header .dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.set-result-custom-header .dd-menu .dd-menu-items ol li>*:focus {
  color: black!important;
}

.set-result-custom-header .dd-menu .dd-menu-items ul li.disabled,
.set-result-custom-header .dd-menu .dd-menu-items ul li.disabled:hover:not([role="separator"]):not(.separator):not(.drop-down-menu-icon-row),
.set-result-custom-header .dd-menu .dd-menu-items ul li.disabled:not(.drop-down-menu-icon-row)>*:focus,
.set-result-custom-header .dd-menu .dd-menu-items ol li.disabled:hover:not([role="separator"]):not(.separator),
.set-result-custom-header .dd-menu .dd-menu-items ol li.disabled>*:focus {
  cursor: default!important;
  color: #c1c1c1!important;
}

.setResultOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.4;
  background: #000;
  z-index: 50;
}

.set-result-custom-header.header-section {
  padding: 0;
}

.header-container {
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.header-section {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
}

.header-section .toggleBtn-container {
  font-weight: bold;
  font-size: 16px;
}

.header-section-left {
  border-right: 1px solid var(--main-border-color);
  flex-basis: 70%;
}

.header-section-right {
  flex-basis: 30%;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.arrow-element {
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-element.active {
  border-color: rgba(0, 0, 0, 1);
}

.arrow-down {
  transform: rotate(45deg);
}

.arrow-up {
  transform: rotate(-135deg);
}

.set-result-page .set-result__button {
  align-items: center;
}
