/*Aligns*/
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.vertical-align-top{
  vertical-align: top;
}
.vertical-align-middle{
  vertical-align: middle;
}
/*Displays and positions*/
.inline{
  display: inline;
}
.inline-block{
  display: inline-block;
}
.relative{
  position: relative;
}
/*Margins*/
.m-0{
  margin: 0;
}
.mt-5{
  margin-top: 5px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.mt-15pr{
  margin-top: 15%;
}
.mr-5{
  margin-right: 5px;
}
.mr-15{
  margin-right: 15px;
}
.mr-20{
  margin-right: 20px;
}
.ml-5{
  margin-left: 5px;
}
.ml-15{
  margin-left: 15px;
}
.ml-20{
  margin-left: 20px;
}
.m-5{
  margin: 5px;
}
.m-10{
  margin: 10px;
}
.m-20{
  margin: 20px;
}
.block-center{
  margin: 0 auto;
}
/*Paddings*/
.p-0{
  padding: 0;
}
.p-1{
  padding: 1px;
}
.pt-5{
  padding-top: 5px;
}
.pt-15{
  padding-top: 15px;
}
.pt-15pr{
  padding-top: 15%;
}
.pl-5{
  padding-left: 5px;
}
.pl-10{
  padding-left: 10px;
}
.pl-20{
  padding-left: 20px;
}
.pr-10{
  padding-right: 10px;
}
.pr-5{
  padding-right: 5px;
}
/*Floats*/
.float-right{
  float: right;
}
.float-left{
  float: left;
}
.w-90pr{
  width: 90%;
}
.w-10pr{
  width: 10%;
}
.lh-35{
  line-height: 35px;
}
/*Colors*/
.green{
  color: #3bc73b;
}
.red{
  color: #f92e2e;
}
.gray{
  color: var(--dark-gray);
}
/*Fonts*/
.fs-23{
  font-size: 23px;
}
.bold{
  font-weight: bold;
}
.strongBold {
  font-weight: 700;
}
/* Decorations */
.underlined {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

