.literature__wrapper {
  display: flex;
  padding: 15px 0;

  .nodata {
    width: 100%;
    justify-content: center;
  }
}

.literature__form {
  margin-right: 15px;
  width: 220px;

  .cr-select {
    margin-bottom: 10px;
  }
}

.sort-select {
  margin-left: 1em;
  width: 100px;
}

.show-more-button-wrapper {
  display: flex;
  justify-content: center;
}

.show-more-button {
  text-decoration: underline;
  cursor: pointer;
}
