.relation-map-page {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  padding-top: 60px;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--main-border-color);
    padding-left: 25px;
    padding-right: 25px;
  }

  &__content {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
  }

  &__chart {
    position: relative;
    flex: 1;
  }

  &__sidebar {
    position: relative;
    width: 400px;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: var(--background-light-color);
    flex-shrink: 0;
    overflow-y: auto;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    height: 45px;

    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-dark);
      padding-left: 5px;
      opacity: 0.9;
    }
  }

  &__error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
  }
}
