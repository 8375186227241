.search-page-start {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;

  &__logo {
    display: block;
    height: 100px;
    width: 250px;
    margin-right: 0;
  }

  .search-form {
    width: 500px;
  }
}
