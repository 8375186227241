.network-result-top-container{
  margin: 0 auto;
  flex-wrap: wrap;
}

.setAnalysisNetworkResult{
  min-height: 790px;
}

.node-filter-wrapper {
  display: flex;
  align-items: center;
}

.table-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin-bottom: 15px;
  margin-top: 15px;
}

.network-result-top-container .pagination-wrapper {
  flex-basis: 100%;
}
