.path-details{
  width: 100%;
  height: 100%;
  top: 0;
  overflow-x: scroll;
}

.path-details-columns,
.path-details-container{
  min-height: 100%;
  min-width: 220px;
}

.path-details-columns-sidebar {
  position: sticky;
  top: 50%;
  transform: translateY(-50%); 
}

.path-details-left-column{
  background-color: var(--main-gray);
}

.path-details-left-column-title{
  font-size: 30px;
}

.path-details-left-column-action{
  text-decoration: underline;
  cursor: pointer;
  padding: 5px 0;
}

.path-details-right-column{
  padding: 20px;
}

.path-details-section .path-details-separator {
  height: 2px;
  background-color: var(--main-border-color);
  margin: 15px 0;
}

.path-details-section-header,
.path-details-section .analysis-title.mt-20 {
  padding-right: 10px;
  text-align: left;
}

.path-details-section{
  margin: 10px 0;
}

.path-details-table-view-header{
  width: 900px;
  margin: 10px auto;
}

.path-details-diagram-wrapper .button-container {
  display: flex;
  justify-content: flex-end;
}

.path-details__popup {
  display: flex;
  max-width: 90vw;
  min-width: 90vw;
  max-height: 90vh;
  min-height: 90vh;
}

.path-details__popup-wrap {
  overflow-y: auto;
  width: 100%;
}

