.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;

  &__wrapper {
    display: inline-block;
    margin-bottom: -4px;
  }

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 4px;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 80%;
    }
  }

  &:checked + label::before {
    border-color: var(--secondary-medium);
    background-color: var(--secondary-medium);
    background-image: url('../../../../theme/assets/img/check-mark.svg');
  }

  &:not(:disabled):not(:checked) + label:hover::before {
    border-color: var(--secondary-light);
  }

  &:not(:disabled):active + label::before {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
  }

  &:disabled + label::before {
    background-color: var(--light-dark-gray);
  }
}
