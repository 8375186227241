.autocomplete {
  width: 100%;
  position: relative;

  &__dropdown {
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 200px;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: rgba(0,0,0,0.1) 0px 0px 0px 1px, rgba(0,0,0,0.1) 0px 4px 11px;
    overflow-y: auto;
  }

  &__item {
    cursor: pointer;
    width: 100%;
    padding: 8px 12px;
    color: inherit;
    font-size: inherit;
    text-align: left;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: var(--secondary-light);
    }
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    text-transform: none;
    color: var(--dark-gray);
  }
}