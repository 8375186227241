.new-option-set-page {
  padding-top: 60px;

  &__add-concept {
    width: 50%;
    margin: 0 auto;
    padding: 20px 0;

    label {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  &__info-text {
    padding: 15px 0;
    font-size: 16px;
    color: #787878;
    line-height: 1.5;
    white-space: pre-line;
    text-align: center;
  }
}

.build-heatmap-add-concept {
  .concept-search-form {
    max-width: initial;
  }
}
