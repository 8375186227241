.setanalysis {
  display: flex;
  flex-wrap: wrap;
}

.setanalysis-set {
  width: 33.33%;
  padding: 10px;
  padding-bottom: 15px;
}

.setanalysis-set__wrap {
  position: relative;
  border: 1px solid #e4e3e3;
  border-radius: 5px;
}

.setanalysis-set__wrap_disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.setanalysis-set__inputs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e3e3;
}

.setanalysis-set__input {
  box-shadow: none;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0;
  width: calc(100% - 35px);
  border-top-left-radius: 5px;
}

.setanalysis-set__actions {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.setanalysis-set__btn {
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 33.33%;
  margin: 0 5px;
}

.setanalysis-set__concepts {
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #e4e3e3;
  font-size: 14px;
}

.setanalysis-set__concept {
  display: flex;
  align-items: center;
}
