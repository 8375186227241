.search-publication-sources {
  margin-bottom: 15px;
  overflow-y: auto;
  padding: 0 2px;
  min-height: 32px;
  max-height: 162px;
  height: 100%;

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--primary-medium);
    cursor: pointer;
  }

  &__count {
    min-width: 40px;
    background-color: var(--primary-light);
    margin-left: auto;
    text-align: right;
    color: white;
    padding-right: 5px;
    font-size: 15px;
    border-radius: 3px;
  }

  &__item {
    display: flex;
    margin: 5px 0;
    height: 22px;
    line-height: 22px;

    &_active {
      .search-publication-sources {
        &__name {
          color: var(--secondary-active-medium);
        }

        &__count {
          background-color: var(--secondary-active-medium);
        }
      }
    }
  }
}
