.complex-set-aggregate-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0 , 0.5);
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.complex-set-aggregate-modal-content {
  width: 80%;
  background-color: #FFFFFF;
  padding: 40px;
  position: relative;
}

.complex-set-aggregate-modal-content .page-title {
  padding: 0;
}

.complex-set-aggregate-modal-content .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.complex-set-aggregate-modal-content .params {
  display: flex;
  justify-content: space-between;
}

.complex-set-aggregate-modal-content .params .section {
  flex-basis: 50%;
}

.complex-set-aggregate-modal-content .params .section .element {
  margin-top: 20px;
}

.complex-set-aggregate-modal-content .button-section {
  margin-top: 20px;
}
