.bilevel-partition-diagram circle,
.bilevel-partition-diagram path,
.bilevel-partition-diagram text {
  cursor: pointer;
}

.bilevel-partition-diagram text {
  pointer-events: none;
}

.bilevel-partition-diagram circle {
  fill: none;
  pointer-events: all;
}

.bilevel-partition-diagram #tooltip {
  pointer-events: none;
  background-color: white;
  padding: 3px 5px;
  border: 1px solid black;
  text-align: center;
}

.bilevel-partition-diagram{
  text-align: center;
}

