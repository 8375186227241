.search-page-main {
  width: 100%;
  position: relative;

  &__wrap {
    display: grid;
    grid-template-columns: minmax(25%, 100%) 40% 35%;
  }

  &__graph-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-left: auto;
    padding-top: 20px;
  }

  &__filters {
    display: flex;
    justify-content: flex-end;
    grid-column-start: 1;
    padding-top: 20px;
    padding-top: 20px;
  }

  &__content {
    grid-row-start: 1;
    grid-row-end: 12;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  &__sidebar {
    display: flex;
    grid-row-start: 1;
    grid-row-end: 12;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 20px;
    background-color: var(--background-light-color);
  }

  &__sidebar-wrap {
    max-width: 400px;
  }

  &__total {
    color: var(--primary-dark);
    padding-bottom: 10px;
    text-align: left;
  }
}

.search-page-main--extended {
  .search-page-main {
    &__graph-block {
      align-items: initial;
      grid-column-start: 1;
      grid-column-end: 12;
      grid-row-start: initial;
      grid-row-end: initial;
      align-self: center;
      margin: 0 auto;
    }

    &__filters {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__content {
      grid-column-start: initial;
      grid-row-start: initial;
      grid-row-end: initial;
    }

    &__sidebar {
      grid-column-start: initial;
      grid-row-start: initial;
      grid-row-end: initial;
    }
  }
}

