.create-set-content {
  border-radius: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    font-weight: 600;
    background-color: #ffffff;
    color: var(--primary-medium);
    font-family: 'NotoSans';
  }

  &__block {
    width: 33.33%;
    display: flex;
    align-items: center;

    &_center {
      justify-content: center;
    }

    &_right {
      justify-content: flex-end;
    }

    .circle-btn {
      margin-left: 5px;
    }
  }
}

.create-set-concepts {
  position: relative;
  padding-top: 5px;
  padding-bottom: 10px;
  min-height: 295px;
  background-color: var(--main-gray);
  display: flex;

  &__item {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__item-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    height: 35px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  &__item-relation-map {
    min-width: 29px;
    min-height: 20px;
    background-image: url('../../../../../theme/assets/img/intermediate-relation-icon.png');
    cursor: pointer;
    color: var(--primary-light);
    //margin-left: auto;
    float: right;
    margin-left: 10px;
  }

  &__count {
    cursor: pointer;
    color: var(--primary-light);
    margin-left: auto;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d9534f;
    text-align: center;
    height: 100%;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 58px);
    height: 100%;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__link {
    color: var(--secondary-dark);
    text-decoration: none;
    opacity: 1;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
