.slider {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__wrap {
    overflow: hidden;
    width: inherit;
    height: inherit;
  }

  &__track {
    display: flex;
    width: inherit;
    height: inherit;
    transform: translateX(0);
    transition: transform 0.5s ease;
  }

  &__btn {
    flex-shrink: 0;
  }

  &__slide {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 10px;

    &:last-child {
      padding-right: 0px;
    }
  }

  &_is-active {
    .slider {
      &__wrap {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}