.ranking-settings-modal {
  width: 775px;
}

.ranking-settings-modal {
  width: 700px;
  min-height: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &--rank-as-marker {
    min-height: 360px;
  }

  &__buttons-block {
    margin-top: auto;
  }

  .concept-search-form {
    max-width: initial;
  }
}

.bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.label {
  padding: 10px 0;
  font-weight: bold;
}
