.rank-selection {
  &__title {
    padding-bottom: 10px;
    text-align: center;
  }

  &__method {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 40px;
    background-color: var(--background-light-color);
  }

  &__method-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    //.button,
    //.button-select {
    //  width: calc(25% - 20px);
    //}
  }

  &__category-selection {
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    gap: 20px;
  }

  &__button-bar {
    width: 1250px;
    margin: 0 auto;
    //min-height: 50px;
  }

  &__btn_add_ranking {
  }

  &__btn_filter {
    float: right;
  }

  &__btn_save_as_set {
    float: right;
    margin-right:16px
  }
}
