.search-result-item {
  margin-bottom: 20px;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius);
  padding-top: 10px;
  padding-bottom: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--main-border-color);
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
  }

  &__title {
    padding-right: 10px;
  }

  &__text {
    opacity: 0.6;
  }

  &__info {
    font-size: 15px;
    line-height: 1.6;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
  }

  &__info-item--inline {
    display: inline-block;
  }

  &__link {
    color: var(--secondary-dark);
    text-decoration: none;
    opacity: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  &__yellow {
    opacity: 0.8;
  }
  
  &__yellow--hover:hover {
    cursor: pointer;
  }
  
  b {
    opacity: 0.8;
  }

  &__tooltip {
    max-width: 800px;
  }

  .publication-management {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &__single-citation-button {
      border-radius: var(--border-radius);
      border: none;
      background-color: var(--primary-medium);
      color: white;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background-color: var(--main-border-color);
      }
    }
  }
}
