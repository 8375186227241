.projects-management {
  position: absolute;
  width: 99%;
  height: 100%;
  top: 0;
  //padding-top: 60px;

  &-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .management-container__buttons {
    border-bottom: 1px solid #e0e0e0;
  }

  .projects-info .header-input {
    width: 100%;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    border-top: none;
  }
}

