.search-category {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: var(--border-radius);

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__item-wrap {
    cursor: pointer;
    flex-shrink: 0;
    width: calc(100% / 2);
    height: 34px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;

    &_selected {
      .search-category__item {
        background-color: var(--secondary-active-medium);
        border: 2px solid var(--secondary-active-medium);
        color: #ffffff;
      }
    }
  }

  &__item {
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--background-light-color);
    border: 2px solid var(--background-light-color);
    color: var(--primary-medium);
    font-family: NotoSans;
    overflow: hidden;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.5s;
  
    &_dropdown {
      overflow: unset;
    }

    .dropdown-menu-component {
      min-width: 0;
    }

    &:hover:not( &--disabled),
    &--active {
      background-color: var(--secondary-active-medium);
      border: 2px solid var(--secondary-active-medium);
      color: #ffffff;
    }

    &--disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    width: 100%;
    height: 105px;
  }
}
