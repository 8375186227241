.form-change-password{
  min-width: 350px;
}

.form-change-password input:-webkit-autofill,
.form-change-password input:-webkit-autofill:hover,
.form-change-password input:-webkit-autofill:focus,
.form-change-password input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset, 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08) !important;
}

.change-password-content {
  padding-bottom: 135px;
}
