body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.title {
  font-family: NotoSans;
  color: var(--primary-medium);
  font-size: 30px;
}

.title2 {
  font-family: NotoSans;
  color: var(--primary-medium);
  font-size: 24px;
}

.title3 {
  font-family: NotoSans;
  color: var(--primary-medium);
  font-size: 20px;
}

.title4 {
  font-family: NotoSans;
  color: var(--primary-medium);
  font-size: 18px;
}

.title5 {
  font-family: NotoSans;
  color: var(--primary-medium);
  font-size: 16px;
}

.text {
  font-size: 16px;
  color: var(--dark-gray);
}

.button {
  transition: 0.5s;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-size: 15px;
  line-height: 28px;
  border-radius: var(--border-radius);
  padding: 5px 20px;
  font-family: NotoSans, sans-serif;
  background-color: transparent;
  border: 1px solid var(--dark-gray);
  color: var(--dark-gray);
  min-height: 35px;

  &[disabled],
  &--disabled {
    cursor: not-allowed;
    background-color: var(--light-dark-gray);
    border-color: var(--light-dark-gray);
    opacity: 0.5;
    color: var(--dark-gray);
    pointer-events: none;
  }

  &:hover:not([disabled]):not(.button-primary--disabled) {
    background-color: var(--main-border-color);
  }
}

.button.button-primary,
.btn.btn-success {
  border: 1px solid var(--primary-medium);
  color: var(--primary-medium);
  background-color: transparent;

  &[disabled],
  &--disabled {
    cursor: not-allowed;
    background-color: var(--light-dark-gray);
    border-color: var(--light-dark-gray);
    opacity: 0.5;
    color: var(--dark-gray);
  }

  &:hover:not([disabled]):not(.button-primary--disabled) {
    background-color: var(--primary-medium);
    color: #fff;
  }
}

.button.button-secondary {
  border: 1px solid var(--secondary-active-medium);
  color: var(--primary-medium);
  background-color: var(--secondary-active-light);

  &[disabled],
  &--disabled {
    cursor: not-allowed;
    background-color: var(--light-dark-gray);
    border-color: var(--light-dark-gray);
    opacity: 0.5;
    color: var(--dark-gray);
  }

  &:hover:not([disabled]):not(.button-primary--disabled) {
    background-color: transparent;
  }
}

.button.button-light {
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  background-color: var(--main-gray);
  flex-shrink: 0;
  color: var(--primary-medium);
}

a.button {
  text-decoration: none;
}

a[disabled] {
  pointer-events: none;
}

/*Pagination styles*/
ul.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
}

ul.pagination li {
  display: inline-block;
  cursor: pointer;
}

ul.pagination li:not(.disabled):hover {
  background: #eeeeee;
  color: var(--secondary-medium);
}

ul.pagination li.disabled:hover {
  cursor: not-allowed;
}

ul.pagination li.active {
  cursor: default;
  background: var(--secondary-medium);
  color: white;
}

ul.pagination li a {
  padding: 6px 12px;
  display: block;
  border: 1px solid #ddd;
  margin-left: -1px;
  font-family: NotoSans, sans-serif;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.next a {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

ul.pagination li.previous a {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

ul.pagination .break-me a {
  cursor: default;
}

.paginationContainer {
  text-align: center;
  min-width: 440px;
}

.table-additional-controls {
  text-align: right;
  margin-bottom: 15px;
  margin-top: 15px;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--secondary-dark);

  &:hover {
    text-decoration: underline;
  }
}

.spinner {
  padding-top: 20px;
  color: var(--secondary-dark);
}

.empty-items-message {
  font-style: italic;
  color: #f9332d;
  min-height: 20px;
}

.info-message {
  font-style: italic;
  color: green;
  min-height: 20px;
}

.dropdown__control {
  &--is-focused {
    box-shadow: 0 0 0 1px var(--secondary-medium) !important;
    border-color: var(--secondary-medium) !important;

    &:hover {
      border-color: var(--secondary-medium) !important;
    }
  }
}

.dropdown__value-container {
  font-family: 'Roboto' !important;
}

.dropdown__option {
  &:hover {
    background-color: var(--secondary-light) !important;
  }

  &--is-selected {
    background-color: var(--secondary-medium) !important;
  }
}

.label-disabled~.checkbox {
  background-color: red;
}

.label-disabled+span {
  color: var(--light-dark-gray);
}

.radio-input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &+label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-right: 5px;
  }

  &+label::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid var(--light-dark-gray);
    border-radius: 50%;
  }

  &:not(:disabled):not(:checked)+label:hover::before {
    border-color: var(--secondary-light);
  }

  &:not(:disabled):active+label::before {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
  }

  &:checked+label::before {
    border-width: 4px;
    background-color: #ffffff;
    border-color: var(--secondary-medium);
  }

  &:disabled+label::before {
    background-color: var(--secondary-light);
  }

  &_no-margin {
    &+label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      margin-right: 0;
    }
  }

}

.sorting-section {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 5px;

  i {
    display: block;
    position: relative;
    color: #b9b9b9;
  }

  i.fa-sort-asc {
    top: 3px;
  }

  i.fa-sort-desc {
    bottom: 3px;
  }

  i.active {
    color: #000;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0)
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.container-big {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1420px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.euretos-logo {
  width: 100%;
  height: 100%;
  background-image: url('../../theme/assets/img/euretos-logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.euretos-logo-white {
  width: 100%;
  height: 100%;
  background-image: url('../../theme/assets/img/euretos-logo-white.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.textarea-input {
  flex: 1;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--background-light-color);
  border: none!important;
  min-height: 100px;
}

.pagination-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  padding-top: 80px;
}
