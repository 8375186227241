.setAnalysisMethodSelection textarea.textarea{
  width: 50%;
  min-width: 300px;
  margin-top: 40px;
  height: 235px;
}

.tabs{
  margin: 0;
  padding-left: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
}

.tabs:after{
  content: " ";
  display: table;
  clear: both;
}

.tabs>li{
  float: left;
  position: relative;
  display: block;
  margin-bottom: -1px;
}

.tabs>li>p{
  color: var(--primary-medium);
  font-family: 'NotoSans';
  margin: 0 2px 0 0;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
  position: relative;
  display: block;
  cursor: pointer;
}

.tabs>li>p:hover{
  border-color: #eee #eee #ddd;
  text-decoration: none;
  background-color: #eee;
  color: black;
}

.tabs>li.active>p{
  color: var(--secondary-active-medium);
  font-family: 'NotoSans';
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.tabs-container{
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.tabs-container button.search-category__item{
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 24px;
  cursor: pointer;
}

.tabs-container .button {
  width: 90%;
  font-size: 14px;
}

.ranking__button {
  justify-content: center;
}

.category-type,
.tissue-type{
  margin: 5px;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 3px;
  background: #f2f2f2;
  cursor: pointer;
  color: #4b4b4b;
}

.tissue-type-active,
.category-type-active {
  background: #c2c2c2;
  color: black;
}

.category-type:hover,
.tissue-type:hover{
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  color: black;
}

.association-term {
  margin: 0 3px;
}

.label-disabled {
  color: darkgrey;
}

.bottom-buttons {
  bottom: 0px;
  width: 100%;
}

button.btn.ranking-button{
  margin: 0;
  width: 100%;
}

.quick-select-block {
  min-width: 170px;
}

.quick-select-block > select.select{
  width: 100%;
  margin: 5px 0;
}

.quick-select-block > label{
  font-size: 18px;
}

.methods-container {
  margin-top: 40px;
}

.setAnalysisMethodSelection .button-secondary {
  display: inline;
  max-height: 55px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 35px;
}

.setAnalysisMethodSelection {
  .first-info-icon {
    font-size: 16px;
  }

  .gene-set-options__tooltip {
    max-width: 350px;
    padding: 2px 7px 7px 7px;
    pointer-events: auto !important;
    h4 {
      margin-bottom: 5px;
    }
    ul {
      text-align: left;
    }
  }

  .gene-details-info__text {
    display: inline-block;
  }
}

.helptext__content {
  position: relative;

  i {
    position: absolute;
    left: 97%;
  }
}

.helptext__text {
  padding-top: 15px;
  font-size: 14px;

  a {
    color: var(--secondary-dark);
  }
}

.tooltip__img {
  display: block;
  max-width: 280px;
  min-height: 170px;
  height: auto;
  margin: auto;
  padding-bottom: 10px;
}

.option-link {
  display: inline-block;
  margin-left: 5px;
  color: var(--secondary-dark);
}

.setAnalysisMethodSelection-trim-text {
  text-overflow: ellipsis;
  //white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
