.bar-chart {
  width: 100%;
  overflow-x: scroll;
}

.bar-chart .bar {
  fill: dodgerblue;
}

.bar-chart .axis text {
  font: 12px sans-serif;
}

.chart-tooltip-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  pointer-events: none;
  max-width: 200px;
  min-width: 50px;
  text-align: center;
  z-index: 10;
}

.chart-tooltip-bar__content {
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
}

.bar-chart-export-button {
  left: 80%;
  position: relative;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: #000000;
  border-color: #000000;
  box-shadow: none;
  border: 1px solid;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  width: 130px;
  margin-right: 10px;
}

.bar-chart-export-button-wrapper {
  cursor: pointer;
  font-size: 12px;
}

.bar-chart-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;

  &__png {
    margin-right: 20px;
  }

  &__csv {
    margin-right: 20px;
  }
}
