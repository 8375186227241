.info-dialog {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 20px;

  &__content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: pre-line;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .btn {
    margin-right: 20px;
  }
}
