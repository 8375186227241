@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
       url('../assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
       url('../assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
       url('../assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
       url('../assets/fonts/Roboto/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/Noto_Sans/NotoSans-Bold.woff') format('woff'),
       url('../assets/fonts/Noto_Sans/NotoSans-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/Noto_Sans/NotoSans-Regular.woff') format('woff'),
       url('../assets/fonts/Noto_Sans/NotoSans-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'IBMPlexSerif';
  src: url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Bold.woff') format('woff'),
       url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'IBMPlexSerif';
  src: url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Medium.woff') format('woff'),
       url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'IBMPlexSerif';
  src: url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Regular.woff') format('woff'),
       url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'IBMPlexSerif';
  src: url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Light.woff') format('woff'),
       url('../assets/fonts/IBM_Plex_Serif/IBMPlexSerif-Light.woff2') format('woff2');
  font-weight: 300;
}

