.literature-search-modal{
  width: 520px;
}

.synonymsSwitcher{
  margin: 10px 0;
}

.synonymsSwitcher input {
  vertical-align: middle;
}
