.publications-graph {
  display: flex;
  flex-direction: column;

  &__filter {
    display: inline-block;
    align-self: flex-end;
  }

  &__title {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 2px;
    font-size: 14px;
    color: var(--primary-medium);
  }

  .y-axis,
  .x-axis {
    display: none;
  }

  &__no-data {
    .nodata__message {
      white-space: pre-wrap;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      color: var(--dark-gray);
    }
  }

  .rc-slider {
    margin: -35px auto 40px auto;
    height: initial;
    padding: 0 0 5px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      right: -10px;
      top: 50%;
      height: 0.2rem;
      background-color: rgba(0,0,0,0.15);
      transform: translateY(-50%);
    }
  }

  .rc-slider-tooltip-inner {
    color: #000;
    font-size: 10px;
    box-shadow: none;
    padding: 0px 4px;
    min-width: 14px;
    height: 14px;
    transform: rotate(55deg);
    background-color: transparent;
  }

  .rc-slider-tooltip-placement-bottom {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.publications-graph--extended {
  .rc-slider-tooltip-inner {
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
}
