.violin-chart {
  position: relative;

  .violin-chart-btn {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 15px;
    background-color: var(--primary-medium);
  }

  &__csv-text {
    text-align: right;
    padding: 15px 0;
    font-size: 16px;
    color: #787878;
    line-height: 1.5;
    white-space: pre-line;
  }
}

.chart-tooltip--popup {
  z-index: 10;
}

.violin-chart-info {
  position: absolute;
  top: 0px;
  max-width: 500px;
  font-size: 14px;
}

.csv-btn-group.btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;

  .csv-btn-group__spiner {
    margin-right: 10px;
  }

  .btn {
    border: 1px solid #ddd;
    padding: 5px 20px;
  }

  .btn-group__first-btn {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .btn-group__second-btn {
    margin-left: -1px;
  }

  .btn-group__last-btn {
    margin-left: -1px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.violin-chart__controls-wrapper {
  display: flex;

  .gene-expression-sort-methods {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__uniprot,
    &__protein-expression,
    &__expression-sorting {
      display: flex;
      align-items: center;
    }

    &__uniprot,
    &__protein-expression {
      margin-right: 15px;
    }
  }

  .boxplot-controls {
    margin-left: auto;
  }
}
