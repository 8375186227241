.set-diagram {
  position: absolute;
  height: 100%;
  top: 0%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
  }

  &__info-block {
    margin: 15px auto 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__concept-count {
    display: inline-block;
    margin-right: 20px;
    font-weight: bold;
  }

  &__button {
    display: block;
  }

  &__content {
    margin: 40px auto;
    padding: 0 20px;
  }

  &--small {
    width: 100%;
    .set-diagram__content {
      width: 90%;
    }
  }

  &--loading {
    width: 100%;
  }
}
