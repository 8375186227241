.background-set-popup {
  width: 70vw;
  padding-top: 15px;
}

.background-set-inline {
  /*max-width: 900px;*/
  width: 55vw;
  padding-top: 15px;
}

.background-set-popup__title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.background-set-popup__content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.background-set-popup__item {
  border-bottom: 1px solid #e4e4e4;
}

.background-set-popup__item_active {
  border-bottom: none;
}

.background-set-popup__option {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  padding-left: 10px;
  padding-right: 12px;
}

.background-set-popup__subtitle {
  line-height: 1.1;
  max-width: 720px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--dark-gray);
}

.background-set-popup__filer {
  display: flex;
  align-self: stretch;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 58px;
  padding: 0;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  margin-left: 20px;
  margin-right: 20px;
}

.background-set-popup__filer-wrap {
  position: relative;
  margin-left: 21px;
  margin-top: 3px;
  width: 1px;
  height: 15px;
  background-color: var(--primary-medium);
  box-shadow: 6px 0 0 0 var(--primary-medium), 12px 0 0 0 var(--primary-medium);
}

.background-set-popup__filer-wrap:before  {
  content: '';
  position: absolute;
  display: block;
  left: -1px;
  top: 2px;
  width: 3px;
  height: 3px;
  border-radius: 1px;
  background-color: var(--primary-medium);
  box-shadow: 6px 8px 0 0 var(--primary-medium), 12px 4px 0 0 var(--primary-medium);
}

.background-set-popup__spinner {
  margin-left: auto;
}

.background-set-popup__item_active .background-set-popup__option {
  border-bottom: 1px solid #e4e4e4;
}

.background-set-popup__item_selected .background-set-popup__subtitle,
.background-set-popup__option:hover .background-set-popup__subtitle {
  color: #333333;
}

.background-set-popup__item_selected .background-set-popup__option::after {
  content: '';
  display: block;
  width: 7px;
  height: 11px;
  border: solid #4caf50;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin-right: 10px;
  margin-left: auto;
}

.background-set-popup__data {
  height: 0px;
  overflow: hidden;
  transition: height ease-in 0.3s;
}

.background-set-popup__item_active .background-set-popup__data {
  height: 300px;
}

.background-set-popup__data .tabs-wrapper {
  padding: 20px 0 0 0;
}

.background-sets-list__items {
  width: 100%;
}

.background-sets-list__item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-left: 15px;
  padding-right: 15px;
}

.background-sets-list_item__overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height:40px;
}

.background-sets-list__item_filter.background-sets-list__item_active {
  background-color: #f1f1f1;
}

.background-cell-filter__title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
}

.background-cell-filter__option {
  line-height: 22px;
  border-bottom: 1px solid #e4e4e4;
}

.background-cell-filter__option:hover {
  color: var(--primary-medium);
}

.background-sets-list {
  display: flex;
}

.background-sets-list__column {
  width: 50%;
}

.background-sets-list__column_last {
  background-color: #f1f1f1;
  border-left: 1px solid #e4e4e4;
}

.background-sets-settings {
  width: 40px;
  border-left: 1px solid #e4e4e4;
}

.background-sets-sort__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.background-sets-sort__btn::after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-right: 2px solid var(--primary-medium);
  border-bottom: 2px solid var(--primary-medium);
  transform: rotate(45deg);
}

.background-sets-sort__txt {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.background-sets-submit {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.background-sets-submit__btn {
  background-color: rgba(126, 70, 174, 0.9);
  color: white;
  border: none;
  height: 40px;
  width: 100px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  opacity: 0.8;
}

.background-sets-submit__btn:hover {
  opacity: 1;
}
