.complex-set-view-root {
  padding-top: 60px;
}

.complex-set-view-content {
  text-align: center;
  max-height: 90vh;
  overflow: auto;
}

.complex-set-view-content .button-section {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  padding-right: 30px;
}

.complex-set-view-content .table-section {
  margin-top: 20px;
}

.complex-set-view-content .filter-section {
  margin-top: 20px;
}

.complex-set-view-content .filter-section .buttons-block{
  display: flex;
  justify-content: flex-start;
}

.complex-set-view-content .filter-section .filters-block{
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
}

.complex-set-view-content .filter-section .aggregation-rules-block{
  margin-left: 5px;
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
}

.complex-set-view-content .filter-section .filter-content {
  margin-left: 5px;
  width: 300px;
  height: 100px;
  background-color: #fff8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: var(--border-radius);
}

.complex-set-view-content .filter-section .aggregation-rules-block .rule-content {
  width: 300px;
  height: 100px;
  background-color: #ffb08c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: var(--border-radius);
}

.complex-set-view-content .filter-section .aggregation-rules-block .rule-content + .rule-content {
  margin-left: 30px;
}

.complex-set-view-content .filter-section .filter-content + .filter-content {
  margin-left: 30px;
}

.complex-set-header {
  font-size: 23px;
  font-weight: bold;
}

.error-block {
  color: red;
  text-align: center;
}

.error-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0 , 0.5);
  width: 100%;
  height: 100%;
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-modal-container .error-modal-content {
  width: 60vw;
  background-color: #FFFFFF;
  padding: 40px;
  position: relative;
  color: red;
  font-size: 18px;
}

.error-modal-container .error-modal-content .page-title {
  padding: 0px;
  padding-bottom: 20px;
}

.error-modal-container .error-modal-content .error-modal-text {
  text-align: center;
}

.error-modal-container .error-modal-content .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.complex-set-table-header,
.complex-set-table-content {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.complex-set-table-wrapper {
  margin: 0 auto;
}
