.single-cell-filters {
  margin-top: 15px;
  margin-bottom: 15px;

  &__style-line {
    height: 5px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
  }
}

.single-cell-selector {
  width: 300px;

  &__label {
    display: inline-block;
    font-weight: 500;
    color: var(--primary-dark);
    opacity: 0.9;
    min-width: 100px;
  }
}

.single-cell__studies-filters {
  display: flex;
  align-items: center;

  &--colors {
    padding-bottom: 20px
  }

  &--values {
    position: relative;
    padding-top: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 5px;
      background-color: #fff;
      border-radius: 5px;
    }

    .single-cell-selector {
      margin-right: 50px;
    }
  }
}
