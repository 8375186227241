.pub-details-page {
  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: var(--dark-gray);
  }
}

.pub-details-container {
  width: 100%;
  max-width: 1140px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
