.reports-management{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;
}

.report-item{
  height: 240px;
  width: 200px;
  margin: 0 100px;
  text-align: center;
  /*padding: 5px;*/
  -webkit-transform: rotate(90deg) translateX(220px);
  transform: rotate(90deg) translateX(220px);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.report-item-card{
  cursor: pointer;
  background: white;
  border: 1px solid #ddd;
}

.report-item-content{
  color: var(--dark-gray);
  height: 90%;
}

.reports-slider{
  display: block;
  width: calc(375px + 1px);
  height: 100vw;
  margin: 0;
  padding-top: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transform: rotate(-90deg) translateY(-375px);
  transform: rotate(-90deg) translateY(-375px);
  -webkit-transform-origin: right top;
  transform-origin: right top;
  position: relative;
  top: -17px;
  padding-left: 41px;
}

.slider-reports-wrapper{
  height: 350px;
  background-color: #F2F2F2;
  overflow: hidden;
}

.report-item-top-bar,
.report-item-bottom-bar{
  color: var(--dark-gray);
  text-align: left;
  padding: 5px 0;
  min-height: 30px;
  cursor: default;
}

.report-item-bottom-bar{
  padding-top: 15px;
}

.report-item-blank{
  padding-top: 36%;
}

.report-item-mind-map-icon>img{
  width: 150px;
  height: 200px;
}

.report-item-mind-map-icon-default{
  background-image: url('../../../theme/assets/img/mind-map-scratch.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.reports-management .block-center{
  margin: inherit;
}

.reports-management-container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reports-info{
  flex: 1;
  overflow: auto;
}

.reports-management .ReactVirtualized__Table__headerRow {
  background-color: #fff;
  border-top: none;
}

.reports-info .ReactVirtualized__Table__rowColumn:not(:last-child) {
  border-right: none;
}

.reports-info .ReactVirtualized__Table__row {
  background-color: #F2F2F2;
  color: var(--dark-gray);
}

.mind-map-small-icon{
  background-image: url('../../../theme/assets/img/small-mind-map-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
}

.reports-info .title-section {
  width: 100%;
}

.reports-info .title-section div{
  display: inline-block;
  vertical-align: middle;
}

.reports-info .title-section .title-section-name{
  padding-left: 10px;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-medium);
}

.reports-info .tags-section {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reports-info .ReactVirtualized__Table__headerColumn {
  text-align: left;
  padding-left: 10px;
}

.reports-info .header-input{
  width: 55%;
  margin-left: 5px;
  border-radius: var(--border-radius);
  background-color: var(--background-light-color);
  box-shadow: none;
}

.reports-info .header-section>span{
  padding: 0 5px;
}

.reports-management-edit{
  background-image: url('../../../theme/assets/img/edit-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 25px;
  cursor: pointer;
}

.reports-management-save{
  background-image: url('../../../theme/assets/img/save-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
}

.reports-management-controls{
  margin-left: auto;
  margin-right: 15px;
}

.reports-management-controls div{
  display: inline-block;
  margin: 0 5px;
}

.concepts-wrapper{
  width: 100%;
}

.concepts-list{
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.concepts-list.concept-list-wide{
  white-space: normal;
}

.concepts-list-controls,
.concepts-list {
  display: inline-block;
  vertical-align: middle;
}

.concepts-list-controls{
  font-size: 22px;
  cursor: pointer;
}

.report-item-bottom-bar-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-wrap .btn-icon-danger{
  margin: 0;
}
