.similar-publication-item {
  display: flex;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;

  &__score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: var(--secondary-medium);
    padding-left: 8px;
    padding-right: 8px;
  }

  &__score-label {
    display: block;
    font-size: 13px;
    padding-bottom: 3px;
  }

  &__score-text {
    font-size: 17px;
    font-weight: 500;
  }

  &__content {
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__block {
    line-height: 1.5;
    padding-bottom: 4px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  &__label {
    font-weight: 500;
  }

  &__text {
    opacity: 0.7;
  }

  &__link {
    color: var(--secondary-dark);
    text-decoration: none;
    opacity: 1;
    padding-bottom: 5px;

    &:hover {
      text-decoration: underline;
    }
  }
}
