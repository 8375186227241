.save-as-set-content {
  padding-top: 25px;
  padding-bottom: 30px;

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.delete-icon {
  color: #d9534f;
  padding-left: 3px;
  cursor: pointer;
}

.disambiguation-message {
  margin: 0 auto;
  padding: 15px 20px 15px 0;
}

.form-control-group {
  padding: 15px;
  border: 1px solid var(--main-border-color);
  box-shadow: 0 1px 3px 0 var(--main-border-color);
}