.indication-finder {
  display: flex;
  width: 100%;
  height: calc(100vh - 213px);

  &__sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 0 20px;
    background-color: var(--primary-medium);
  }

  &__sidebar-text {
    color: #fff;
    font-size: 42px;
    font-family: NotoSans;
    text-align: center;
    text-transform: uppercase;
  }

  &__select-gene {
    max-width: 300px;
    padding: 0;
  }

  &__main {
    width: 80%;
    padding: 0px 50px;
  }

  &__main-text {
    line-height: 1.5;
    color: var(--primary-medium);
  }

  &__label {
    width: 400px;
    color: var(--dark-gray)
  }

  &__btn {
    width: 300px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    background-color: var(--primary-medium);
    text-transform: uppercase;
  }

  &__select-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
  }
}
