.publications-list {
  display: flex;
  flex-direction: column;
  width: 70vw;
  padding: 10px;

  &__wrap {
    overflow-y: auto;
    padding: 0;
  }

  &__title {
    padding-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }

  &__redirect {
    text-align: center;
    margin-bottom: 10px;
  }

  &__content {
    position: relative;
    padding-right: 10px;
  }

  &__controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .paginationContainer {
    min-width: 340px;
  }

  .pagination {
    margin: 0;
  }
}

.publication-common-links-wrapper {
  position: relative;
  text-align: center;
}

.pub-details__triples {
  .search-page__recommendation_block {
    margin-top: 15px;
    padding-bottom: 0;
  }
}