.publications-list-item {
  padding-bottom: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 15px;
  border-bottom: 1px solid var(--main-border-color);

  &_selected {
    background-color: rgb(245, 241, 241);
  }

  &__title-wrapper {
    position: relative;
    margin-bottom: 5px;
    padding-left: 25px;
  }

  &__checkbox {
    position: absolute;
    top: 6px;
    margin-left: -25px;
  }

  &__title {
    display: inline-block;
    font-size: 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__date {
    color: darkgray;
    padding-right: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-right: 15px;
    }
  }

  &__info {
    color: var(--secondary-dark);
    padding-top: 5px;

    .link {
      color: var(--secondary-dark);
    }
  }

  &__highlight {
    font-weight: bold;
  }
}

.publication-management {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &__single-citation-button {
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--primary-medium);
    color: white;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

