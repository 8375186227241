.relation-map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;

  .title-overflow {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.project{
  height: 240px;
  width: 200px;
  margin: 0 100px;
  text-align: center;
  -webkit-transform: rotate(90deg) translateX(220px);
  transform: rotate(90deg) translateX(220px);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.project-card{
  cursor: pointer;
  background: white;
  border: 1px solid #ddd;
}

.project-content{
  color: var(--dark-gray);
  height: 90%;
}

.projects-slider{
  display: block;
  width: calc(375px + 1px);
  height: 100vw;
  margin: 0;
  padding-top: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transform: rotate(-90deg) translateY(-375px);
  transform: rotate(-90deg) translateY(-375px);
  -webkit-transform-origin: right top;
  transform-origin: right top;
  position: relative;
  top: -17px;
  padding-left: 41px;
}

.slider-projects-wrapper{
  height: 350px;
  background-color: #f2f2f2;
  overflow: hidden;
}

.project-top-bar,
.project-bottom-bar{
  color: var(--dark-gray);
  text-align: left;
  padding: 5px 0;
  min-height: 30px;
  cursor: default;
}

.project-bottom-bar{
  padding-top: 15px;
}

.project-blank{
  padding-top: 36%;
}

.project-mind-map-icon>img{
  width: 200px;
  height: 200px;
}

.project-mind-map-icon-default{
  background-image: url('../../../theme/assets/img/mind-map-scratch.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.relation-map .block-center{
  margin: inherit;
}

.relation-map-container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projects-info{
  flex: 1;
  overflow: auto;
}

.relation-map .ReactVirtualized__Table__headerRow {
  background-color: #fff;
  border-top: none;
}

.projects-info .ReactVirtualized__Table__rowColumn:not(:last-child) {
  border-right: none;
}

.projects-info .ReactVirtualized__Table__row {
  background-color: #F2F2F2;
  color: var(--dark-gray);
}

.mind-map-small-icon{
  background-image: url('../../../theme/assets/img/small-mind-map-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
}

.projects-info .title-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.projects-info .title-section div{
  display: inline-block;
}

.projects-info .title-section .title-section-name {
  padding-left: 10px;
  display: inline-flex;
  max-width: calc(100% - 30px);
  align-items: center;
}

.projects-info .ReactVirtualized__Table__headerColumn {
  text-align: left;
}

.projects-info .header-input{
  width: 55%;
  margin-left: 5px;
  border-radius: var(--border-radius);
  box-shadow: none;
  background-color: var(--background-light-color);
}

.concepts-wrapper{
  width: 100%;

  .table-wrap__cell-row {
    padding-left: 5px;

    &:last-child {
      padding-left: 0;
    }
  }
}

.relation-map-tags-list{
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.concepts-list{
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.concept-list-wide{
    white-space: normal;
  }

  .table-wrap__cell-row {
    display: inline;
  }
}

.concepts-list-controls,
.concepts-list {
  display: inline-block;
  vertical-align: middle;
}

.concepts-list-controls{
  font-size: 22px;
  cursor: pointer;
}

.project-bottom-bar-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
