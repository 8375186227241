.freeuser-confirmation-dialog {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
