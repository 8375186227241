.select {
  width: 100%;

  .dropdown__placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 98%;
  }

  &.dropdown--is-disabled {
    opacity: 0.5;
  }
}

.selector--mandatory .css-yk16xz-control,
.selector--mandatory .css-1qfqsfn-control{
  border-left: 3px solid #ff0000;
}
