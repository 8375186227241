.short-concept-card {
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  text-align: left;

  &__name {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.8px;
  }

  &__name:not(:first-child) {
    border-top: 1px solid rgba(0,0,0,.12);
  }

  &__block {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: normal;
  }

  &__text {
    color: rgba(0,0,0,.6);

    &_bold {
      font-weight: bold;
    }

    &_cat {
      color: var(--primary-light);
    }
  }

  &__link {
    color: var(--secondary-dark);
  }
}
