.relation-map-relations {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px;

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: var(--background-light-color);
    opacity: 0.8;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    transition: opacity ease-in 0.3s;

    &_active {
      opacity: 1;

      .relation-map-relations__text {
        font-weight: 500;
      }
    }

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .circle-btn {
      width: 26px;
      height: 26px;
      background-color: var(--main-border-color);
      margin-left: auto;
      margin-right: 5px;
      color: var(--primary-medium);
    }
  }

  &__text {
    color: var(--primary-dark);
    font-size: 14px;
  }

  &__input {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    margin-left: auto;
    height: 25px;
    color: var(--primary-dark);
    background-color: var(--main-border-color);
    text-align: center;
    font-weight: 500;
  }
}
