.upload-set {
  display: block;
  width: 900px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-file {
    padding-bottom: 0px;
  }
}