.set-data-selection{
  margin-left: 50px;

  .header-section {
    justify-content: center;
  }
}

.green-color{
  color: green;
}

.red-color{
  color: red;
}

.headerLabelWithCheckbox{
  display: inline-block;
  width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
