body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
* {
  box-sizing: border-box;
}
.h-100pr{
  height: 100%;
}
/*Grid*/
.flex-grid {
  display: flex;
}
.col-1 {
  flex: 1;
}
.col-2 {
  flex: 2;
}
.col-3 {
  flex: 3;
}
.col-4 {
  flex: 4;
}
.col-5 {
  flex: 5;
}
.col-6 {
  flex: 6;
}
.flex-grid .align-self-center {
  align-self: center;
}
.flex-grid.justify-content-space-around {
  justify-content: space-around;
}
.flex-grid.justify-content-center {
  justify-content: center;
}
.flex-grid.justify-content-flex-end {
  justify-content: flex-end;
}
.row {
  margin: 10px 0;
}
/*Modal dialog styles*/
.cerebrumModalDialog {
  border-radius: 0px!important;
  top: 15%!important;
  max-width: 80%;
  position: static!important;
  margin: 0 auto!important;
  margin-top: 80px!important;
}
.cerebrumModalDialog > a {
  top: -20px!important;
  right: -20px!important;
  left: initial!important;
}
.error-text {
  color: #f9332d;
  padding: 5px;
  font-style: italic;
}
.page-title{
  text-align: center;
  font-size: 25px;
  padding: 20px;
}
textarea {
  resize: none;
}
form .form-label,
form .form-control{
  display: inline-block;
}
form .form-label{
  width: 20%;
  font-size: 18px;
  font-style: italic;
}
form .form-control{
  width: 80%;
}
.form-label-top{
  vertical-align: top;
  padding-top: 5px;
}
.fs-50{
  font-size: 50px;
}

body.ReactModal__Body--open {
  overflow: hidden;
}
