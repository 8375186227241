.gene-details-definition {
  &__synonyms {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 50% 50%;

    .col-1 {
      grid-column: 1;
    }

    .col-2 {
      grid-column: 2;
    }
  }

  &__section {
    line-height: 1.5;
  }

  .ideogram-container {
    margin-bottom: -10px;
  }

  #_ideogram.labeledLeft {
    padding: 0;
  }

}
