.gene-details {
  display: flex;
  width: 100%;
  padding-top: 60px;

  &__sidebar {
    width: 260px;
    background-color: var(--main-gray);
  }

  &__sidebar-wrap {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    position: sticky;
    top: 60px;
  }

  &__content {
    width: calc(100% - 260px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.concept-name_fixed {
  position: sticky;
  top: 60px;
  background-color: #ffffff;
  border-bottom: 2px solid var(--main-border-color);
  z-index: 2;
}

.concept-details-columns,
.concept-details-container {
  min-width: 220px;
}

.concept-details-columns-sidebar {
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 60px;
  padding-bottom: 20px;
}

.concept-details-menu {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 15px;
}

.concept-details-left-column{
  background-color: var(--main-gray);
}

.concept-details-left-column-action,
.concept-details-accordion-header__text,
.concept-details__accordion-menu-item {
  color: var(--primary-dark);
  cursor: pointer;
  transition: 0.5s;
  padding: 5px;
}

.concept-details-left-column-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.concept-details-left-column-action,
.concept-details-accordion-header__text {
  text-decoration: underline;
}

.concept-details-accordion-header__text,
.concept-details__accordion-menu-item {
  font-size: 14px;
}

.accordion__icon {
  color: var(--primary-dark);
  cursor: pointer;
  transition: 0.5s;
}

.disabled {
  color: lightgrey;
}

.concept-details-left-column-action:not(.disabled):hover,
.concept-details-left-column-action:not(.disabled):hover .accordion__icon,
.concept-details__accordion-item:not(.disabled):hover .concept-details-accordion-header__text,
.concept-details__accordion-menu-item:not(.disabled):hover,
.concept-details__accordion-item:not(.disabled):hover .accordion__icon {
  color: var(--secondary-active-medium);
}

.disabled {
  .concept-details-accordion-header__text {
    color: lightgrey;
  }

  .accordion__icon {
    color: lightgrey;
  }

  .concept-details__accordion-item {
    color: lightgrey;
  }
}

.concept-details__accordion-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.concept-details__accordion-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.concept-details-right-column{
  padding: 20px;
}

.concept-name-gene-details .concept-name-gene-details-title {
  padding: 15px 0;
}

.concept-name-gene-details-wrapper {
  display: flex;
}

.concept-details-separator{
  height: 2px;
  background-color: var(--main-border-color);
}

.concept-details-separator-small{
  width: 98%;
}

.concept-category{
  padding: 5px 0;
  font-style: italic;
  color: var(--dark-gray);
}

.concept-definition-title,
.concept-key-aspects-title,
.concept-category-analysis-title{
  padding-right: 10px;
}

.concept-sub-title{
  font-weight: bold;
  padding-top: 10px;
}

.concept-synonyms-link {
  color: var(--secondary-dark);
}

.concept-synonyms-link:hover {
  cursor: pointer;
}

.concept-key-aspect{
  padding: 10px;
}

.concept-key-aspect-category{
  font-weight: bold;
  padding-bottom: 5px;
}

.buttonContainer {
  margin-top: 10px;
  text-align: center;
}

.flex-column-auto {
  display: flex;
  flex-direction: column;
}

.gene-column {
  flex: 50%;
}

.gene-details-section-title {
  border-bottom: 1px solid var(--main-border-color);
  padding: 20px;
  font-weight: 600;
}

.gene-details-section-body {
  margin: 20px;
  min-height: 50px;
}

.lowercase {
  text-transform: lowercase;
}

.gene-details__no-data {
  color: var(--dark-gray);
}

.therapeutic-candidates__no-data_block {
  padding-top: 20px;
  padding-bottom: 30px;
  margin: auto;
}

.gene-details-info__btn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.gene-details-info__main {
  line-height: 1.5;
  background-color: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;

  a {
    color: var(--secondary-dark);
  }
}

.gene-details-info__main p {
  margin: 0;
  margin-bottom: 10px;
}

.gene-details-info__main_light {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: none;
}

.gene-details-info__dataset-block {
  display: flex;
  justify-content: center;
  align-items: center;

  .single-cell-study__dataset-block {
    margin-right: 30px;
  }
}

.gene-expression__select {
  max-width: 500px;
  margin: auto;
  padding-top: 15px;

  .dropdown__single-value {
    width: 100%;
    padding-right: 10px;
  }
}

.gene-details-transformed-buttons {
  position: relative;
  bottom: 0;
  left: 62%;
  font-weight: bold;
}

.export-button-wrapper {
  position: relative;
  bottom: 0;
  float: right;
  width: 50px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.export-button {
  width: 25px;
  height: 25px;
  background-image: url('../../theme/assets/img/save-file-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.gene-details-export-btn:hover {
  opacity: 0.8;
}

.spinner-wrapper-center {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls-block-2items {
  display:flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .export-to-csv {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.controls-block-2items:before {
  content: '';
  visibility: hidden;
  width: 100px;
}

.cell-line-chart-modal .modal-content{
  justify-content: flex-start;
}

.gene-expression-block-diagram .extarnal-links__title {
  margin: 10px;
}

.gene-expression-block-diagram .external-links__list {
  padding: 0 10px;
}

.healthy-cells-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__median {
    color: var(--primary-light);
  }
}

.protein-location {
  .controls-block-2items {
    margin-top: 15px;
  }
}

.cell-line-diagram {
  .boxplot-controls {
    //position: relative;
    justify-content: left;
    left: 125px;
  }

  .boxplot-chart {
    padding-top: 35px;
  }
}

.gene-expression-sort-methods {
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    opacity: 0.9;
    font-weight: 500;
    color: var(--primary-dark);
  }

  &__selector {
    width: 250px;
    padding: 15px 0;
    margin-left: 15px;
  }
}

.gene-expression-visible-hpa-chart__selector-block {
  display: flex;
  align-items: center;
}

.gene-expression-sort-methods__selector-block {
  display: flex;
  align-items: center;

  &--sorting,
  &--filtering-by-cell-type-groups {
    margin-right: 15px;
  }
}

.gene-expression-boxplot-wrapper {
  position: relative;

  .legend-wrapper {
    top: 0px;
    padding: 0;
    max-width: 200px;
    background-color: #ffffff;
  }

  .boxplot-chart {
    padding-left: 200px;
  }
}

.gene-expression-beeswarm-wrapper {
  position: relative;

  .legend-wrapper{
    top: 0px;
    padding: 0;
    max-width: 200px;
    background-color: #ffffff;
  }

  .beeswarm-graph {
    padding-left: 200px;
  }
}

