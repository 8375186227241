.semantic-type {
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  background-color: #ffffff;
  border-radius: var(--border-radius);

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &_nopadding {
    padding: 0px;
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    padding-left: 5px;
  }

  &__accordion {
    &.accordion-item {
      background-color: #ffffff!important;
    }

    .accordion-item {
      &__title {
        display: flex;
        align-items: center;
        min-height: auto;
        padding: 10px;
        font-weight: 400;
      }

      &__content {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

  }

  &__taxonomie {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    background-color: var(--background-light-color);
    border-radius: var(--border-radius);
  }

  &__taxonomie-title {
    margin-left: auto;
    color: var(--secondary-dark);
    font-size: 14px;
    font-weight: 500;
  }
}