.publications-triples-table .flex-grid.justify-content-flex-end {
  margin: 0 auto;
}

.pub-details__triples {
  margin-bottom: 50px;
}

.publications-triples-table-top-container{
  margin: 0 auto;
}

.filter-control {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.pub-details__triples .paginationContainer {
   margin: 0 auto;
}
