.chart-wrap {
  display: flex;
  position: relative;

  &__fixed-block {
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: #f7f7f7;

    &_left {
      left: 0;
    }
  
    &_right {
      right: 0;
    }
  }
}

.cited-chart,
.cited-by-chart {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.cites-charts {
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  background-color: #f7f7f7;

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  &__description {
    line-height: 1.7;
    opacity: 0.7;
    padding-bottom: 30px;
  }

  .cited-chart-legend {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-gray);
    padding-top: 20px;
    padding-bottom: 40px;
    height: 100%;
  }

  &__block {
    min-width: 50%;
    max-width: 100%;
    padding-bottom: 5px;
  }
}

.cites-tooltip {
  &__wrap {
    max-height: 300px;
    overflow: hidden;
    font-size: 12px;
    padding: 8px;
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
  }
}

.cited-chart-axis-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;

  span {
    padding-left: 10px;
    padding-right: 10px;
  }
}
