.sets-page {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 80vw;
  height: calc(90vh - 20px);

  &__title {
    text-align: center;
    letter-spacing: 0.14px;
    padding-top: 5px;
    padding-bottom: 15px;
  }

  &__content {
    width: 100%;
    height: calc(100% - 150px);
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;

    .controls-block {
      position: sticky;
      top: 0px;
      background-color: #ffffff;
      border-bottom: 1px solid var(--main-border-color);
      z-index: 1;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
    border-top: 1px solid var(--main-border-color);
    margin-left: 30px;
    margin-right: 30px;
  }

  &__btn {
    cursor: pointer;
    height: 36px;
    width: 150px;
    border-radius: var(--border-radius);
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    letter-spacing: 0.3px;
    margin-left: 5px;
    margin-right: 5px;

    &_close {
      color: black;
      border: 1px solid var(--main-border-color);
      font-weight: 400;

      &:hover {
        background-color: var(--main-border-color);
      }
    }

    &_select {
      color: var(--primary-medium);
      border: 1px solid var(--primary-medium);
      font-weight: 500;

      &:hover {
        color: #ffffff;
        background-color: var(--primary-medium);
      }
    }
  }

  &__limit {
    display: flex;
    align-items: center;
    padding-top: 30px;

    &_error {
      .sets-page__limit-text {
        color: red;
      }
    }
  }

  &__limit-text {
    display: block;
    font-size: 16px;
    padding-left: 8px;
    opacity: 0.8;
  }
}
