.form-activate-password{
  min-width: 350px;

  input {
    margin-bottom: 5px;
    border: 1px solid var(--main-border-color);
    border-radius: var(--border-radius);
  }
}

.error-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content.check-token-modal{
  align-items: center;
}
