.heatmap-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px 15px 20px;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
  position: sticky;
  top: 59px;
  background-color: #ffffff;
  z-index: 1;

  &_border-top {
    border-top: 1px solid #e0e0e0;
  }
}

