.concepts-search {
  display: flex;
  width: 60vw;
  min-width: 800px;
  height: 70vh;
  min-height: 500px;
}

.concepts-search-params {
  display: flex;
  width: 75vw;
  min-width: 900px;
  height: 75vh;
  min-height: 500px;
}
