.relation-map-chart {
  position: relative;
  display: flex;
  flex: 1;
  cursor: move;

  .nodes,
  .links {
    cursor: pointer;
  }

  .link-text-circle {
    font-size: 9px;
    font-weight: 500;
  }
  
  .link-text {
    font-size: 11px!important;
    font-style: italic;
  }

  .node-text {
    fill: var(--primary-dark);

    &_selected {
      font-weight: bold;
    }
  }

  &__title {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 30px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    color: var(--primary-dark);
    max-width: calc(100% - 100px);

    &:hover {
      text-decoration: underline;
    }

    span {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__controls {
    position: absolute;
    right: 0;
    bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;

    .zoom-controls__btn {
      background-color: var(--background-light-color);
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      color: var(--primary-medium);
    }
  }
}

.circle-btn {
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: var(--background-light-color);
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--primary-medium);
  transition: transform 0.1s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

#relation-map-chart {
  flex: 1;
}
