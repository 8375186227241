.textarea {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  box-shadow: none;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius);

  &__label {
    display: inline-block;
    padding: 10px 0;
    font-weight: 600;
    color: var(--primary-medium);
    font-size: 14px;
  }
}