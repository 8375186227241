.concept-search-sem-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  &_disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &__content {
    width: 100%;
  }

  .accordion-item {
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--background-light-color);

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      padding: 0px;
      font-weight: 400;
      height: 40px;
      display: flex;
    }

    .circle-btn {
      width: 22px;
      height: 22px;
    }
  }
}