.login h1{
  font-size: 17px;
  color: #999;
}

.logo-container {
  height: 82px;
  background: 50% 0 no-repeat;
  background-size: 100% auto;
}

.login-euretos-logo {
  background-image: url('../../../theme/assets/img/euretos-logo.png');
  background-position: center;
  width: 240px;
}

.form-login {
  max-width: 350px;
  margin: 0 auto;
}

.form-login .input {
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius);
}

.login-help {
  max-width: 350px;
  margin: 0 auto;
}

.login-help a,
.login-help .login-help__btn {
  padding: 0 10px;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--primary-medium);
  font-family: Roboto;
}

.login-help .login-help__btn {
  cursor: pointer;
}

.login__btn {
  margin-right: 10px;
}
