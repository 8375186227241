.rank-selection-concepts-search {
  display: flex;
  width: 80vw;
  min-width: 800px;
  height: 70vh;
  min-height: 500px;
}

.rank-target-params {
  width: 28%;
  background-color: var(--background-light-color);
  //box-sizing: content-box;
  margin: 0px;
  padding: 0px;
}

.rank-selection-btn {
  width: calc(100% - 40px);
  margin: 20px 20px;
  //width: 280px;
}

.get-ranking-btn {
  margin: 20px 20px;
  position: absolute;
  right:    0;
  bottom:   0;
}

.rank-target-params-panel {
  margin: 10px;
}

.rank-target-params-panel-hide {
  display: none;
}

.rank-target-literature-radio {
  margin: 0 0 0 40px;
  //display: flex;
  //flex-direction: row;
}

//.concepts-search-params {
//  display: flex;
//  width: 75vw;
//  min-width: 900px;
//  height: 75vh;
//  min-height: 500px;
//}
