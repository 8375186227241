.circosDiagram {
  text-align: center;
  position: relative;
}

.circos-export-button-wrapper {
  position: relative;
  bottom: 0;
  left: 68%;
  transform: translateX(-50%);
  width: 50px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.export-buttons-section {
  position: absolute;
  left: 68%;
  top: 10px;
  z-index: 1;
}

div.circos-tooltip {
  display: none;
}