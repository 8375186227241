.ReactVirtualized__Table.result-table {
  font-size: 14px;
}

.ReactVirtualized__Table__headerRow {
  background-color: var(--main-gray);
  color: var(--dark-gray);
  border: 1px solid #e0e0e0;
  border-bottom-width: 0px;
  padding-right: 0px!important;
}

.ReactVirtualized__Table__headerColumn {
  text-align: center;
  font-size: 16px;
}

.ReactVirtualized__Table__headerRow {
  text-transform: none;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid{
  border: 1px solid #e0e0e0;
}

.ReactVirtualized__Table__headerColumn:focus,
.ReactVirtualized__Table__sortableHeaderColumn:focus,
.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table__Grid:focus{
  outline: none;
}

.ReactVirtualized__Table__row{
  border-bottom: 1px solid #e0e0e0;
}

.ReactVirtualized__Table__rowColumn {
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table__rowColumn .cell-row:not(:last-child){
  border-bottom: 1px solid #eaeaea;
}

.ReactVirtualized__Table__rowColumn.flexColumn{
  flex-direction: column;
  padding: 0;
  justify-content: center;
}

.ReactVirtualized__Table__rowColumn:not(:last-child){
  border-right: 1px solid #e0e0e0;
}

.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__headerColumn{
  margin-right: 0px;
}

.table-bottom {
  padding: 10px 0;
}

.table-bottom  button.btn{
  margin: 0;
}

.table-bottom .button-container{
  display: inline-block;
  width: 50%;
}

.button-container-right{
  text-align: right;
}

.noRows{
  text-align: center;
  padding: 10px;
  color: gray;
  font-style: italic;
}

.stripped .ReactVirtualized__Table__row:nth-child(even){
  background-color: var(--main-gray);
}

.ReactVirtualized__Table__Grid{
  will-change: inherit!important;
}

.cell-row{
  width: 90%;
  font-size: 15px;
}

.cell-row-normal-height{
  line-height: normal;
}

.scrollable-x-table{
  overflow-x: auto;
}

.scrollable-x-table .dd-menu-items .dd-item-ignore ul {
  overflow-x: auto;
  height: 150px;
}

.ambiguity-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.dynamic-height-table {
  display: flex;
  flex-direction: column;
}

.dynamic-height-table__wrap {
  flex: 1 1 auto;
}

.ambiguity-row-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
} 

.ambiguity-row-name {
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactVirtualized__Table__row {
  &:last-child {
    border-bottom: none;
  }
}

.table-header {
  &__cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 10px;
  }

  &__text {
    padding-right: 10px;
  }

  &__filter {
    flex: 1;
    max-width: 150px;

    input {
      border: none;
    }
  }
}

.table-wrap {
  height: 100%;

  &__cell {
    position: relative;
    white-space: normal;
    line-height: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    text-align: center;
    padding: 5px;

    &_left {
      text-align: left;
      align-items: flex-start;
    
      .table-cell {
        align-items: flex-start;
      }
    }

    &_center {
      .table-cell {
        align-items: center;
      }
    }
  }

  &__cell-row {
    width: 100%;
  }

  &__cell-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.table-cell-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .table-wrap__cell-row {
    padding-right: 4px;
  }

  &_break-all {
    word-break: break-all;
  }
}

.table-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  position: relative;
  white-space: normal;
  line-height: 24px;
  text-transform: capitalize;
  word-break: break-word;
}