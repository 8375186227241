.dropdown-menu-component.dd-menu .dd-menu-items ul li .dropdown-menu-component{
  padding: 8px;
}

.toggle-icon{
  padding-left: 5px;
}
.fa-arrow-up{
  padding-left: 5px;
}
.fa-arrow-down{
  padding-left: 5px;
}

.dropdown-menu-component{
  width: 100%;
  min-width: 190px;
}

.dropdown-menu-component>button.btn {
  width: 100%;
}

.dropdown-menu-component>button.btn>div {
  max-width: 90%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.penaltyTissuesMenuItems{
  min-width: 230px!important;
}

.toggleBtn {
  vertical-align: middle;
}

.dd-menu .toggleBtn-container {
  margin: 3px;
  align-items: center;
  justify-content: center;
}

.dd-menu .dd-menu-items ul li.disabled {
  opacity: 0.7;
  background: #fafafa;
}

.dd-menu .dd-menu-items ul li.disabled:hover {
  cursor: not-allowed;
  background: #fafafa !important;
}

.dd-menu li.disabled .dropdown-menu-component__arrow {
  display: none;
}
