.concept-details {
  width: 100%;
  padding-top: 60px;
}

.concept-details_popup {
  padding-top: 0;
}

.concept-details-columns,
.concept-details-container {
  min-width: 220px;
}

.concept-details-columns-sidebar {
  position: fixed;
  left: 30px;
  top: 13%;
}

.concept-details-left-column{
  background-color: var(--main-gray);
}

.concept-details-left-column-action{
  color: var(--primary-dark);
  text-decoration: underline;
  cursor: pointer;
  padding: 5px;
  transition: 0.5s;
}

.concept-details-left-column-action:not(.disabled):hover {
  color: var(--secondary-active-medium);
}

.concept-details-left-column-action.disabled {
  color: lightgrey;
  cursor: pointer;
}

.concept-details-right-column{
  padding: 20px;
}

.concept-name{
  color: var(--primary-medium);
  font-size: 30px;
  padding: 15px 0;
}

.concept-details-separator{
  height: 2px;
  background-color: var(--main-border-color);
}

.concept-details-separator-small{
  width: 98%;
}

.concept-category{
  padding: 5px 0;
  font-style: italic;
  color: var(--dark-gray);
}

.concept-definition,
.concept-publications,
.concept-context,
.concept-key-aspects,
.concept-category-analysis{
  padding-top: 50px;
}

.concept-definition-title,
.concept-publications-title,
.concept-context-title,
.concept-key-aspects-title,
.concept-category-analysis-title{
  color: var(--primary-medium);
  font-family: NotoSans;
  font-size: 22px;
  padding: 10px 10px 10px 0;
}

.concept-publication-title {
  padding-right: 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

.concept-details-card{
  max-width: 300px;
  word-break: break-all;
  border: 2px solid var(--main-border-color);
}

.concept-details-card-title{
  text-align: center;
  font-size: 20px;
  padding: 10px;
}

.concept-details-card-image {
  display: block;
  text-align: center;
  border-top: 2px solid var(--main-border-color);
}

.concept-details-card-section{
  padding: 10px;
  background-color: rgba(255, 243, 132, 0.5);
  text-align: center;
  font-size: 20px;
}

.concept-details-card-synonyms,
.concept-details-card-measures{
  text-align: center;
  padding: 15px;
}

.concept-details-card-measure-block{
  padding-bottom: 5px;
}

.concept-details-card-measure-value{
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}

.concept-publication{
  padding-top: 15px;
}

.concept-word-cloud,
.concept-context-diagram{
  margin: 10px;
}

.concept-context-diagram{
  text-align: center;
}

.concept-key-aspect{
  padding: 10px;
}

.concept-key-aspect-category{
  font-weight: bold;
  padding-bottom: 5px;
}

.concept-key-aspect-concept:hover{
  cursor: pointer;
  text-decoration: underline;
}

.concept-key-aspect-concept{
  color: var(--secondary-dark);
}

.concept-category-analysis-table{
  padding-top: 20px;
}

.buttonContainer {
  margin-top: 10px;
  text-align: center;
}

.analyze-button.analyze-button {
  margin: 0px;
}

.concept-key-aspects-data__content {
  flex-wrap: wrap;
}
