.pathways-dropdown {
  position: relative;
  height: inherit;
  line-height: inherit;
  padding: 10px;
}

.pathways-dropdown__button {
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  width: 100%;
  line-height: 1.3;
}

.pathways-dropdown__button:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 10px;
  margin-bottom: 2px;
  border-bottom: 2px solid var(--primary-medium);
  border-right: 2px solid var(--primary-medium);
  transform: rotate(45deg);
}

.pathways-dropdown__block {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 100%;
  left: 0;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  max-height: 200px;
  background-color: var(--main-gray);
}

.pathways-dropdown__wrap {
  max-height: 180px;
  overflow-y: auto;
}

.pathways-dropdown__item {
  color: var(--dark-gray);
  cursor: pointer;
  line-height: 1.6;
}

.pathways-dropdown__item_active {
  color: var(--primary-medium);
  pointer-events: none;
  opacity: 0.7;
}

.pathways-dropdown__item_active-select::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background-image: url('../../../theme/assets/img/green_tick.png');
  background-size: cover;
}

.pathways-dropdown__item_active-select {
  color: var(--primary-medium);
  opacity: 0.7;
}

.pathways-dropdown__item:hover {
  color: #000000;
}

.pathways-selector {
  max-width: 500px;
  margin: auto;
  padding-top: 15px;
}

.pathways__picture {
  margin: 30px 0;
}

.pathways__no-data {
  padding: 20px;
  color: var(--dark-gray);
}
