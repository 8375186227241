.concepts-search-sidebar {
  display: flex;
  flex-direction: column;
  width: 360px;
  padding-top: 15px;
  background-color: var(--background-light-color);

  &__3blocks {
    width: 25%;
    max-width: 360px;
    min-width: 300px;
  }

  &__title {
    padding-bottom: 10px;
    padding-left: 15px;
  }

  &__content {
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 7px;
  }

  &__other {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__accordion {
    .accordion-item__title {
      padding-top: 0px;
      padding-bottom: 0px;
      height: 40px;
      display: flex;
    }
  
    .circle-btn {
      width: 22px;
      height: 22px;
    }
  }
}
