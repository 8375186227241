.heatmap-chart {
  position: relative;
  padding-bottom: 45px;

  &__content-wrapper {
    position: relative;
    padding-top: 35px;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
  }

  &__title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__no-data {
    margin: 0 35%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
  }

  &__legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__legend-text {
    padding-bottom: 15px;
    font-weight: 600;
  }

  rect {
    &.selection {
      stroke: var(--secondary-dark)!important;
      stroke-width: 1.5px;
      stroke-dasharray: 5px;
      stroke-opacity: 1;
      fill: transparent;
      pointer-events: none;
    }

    &.cell {
      &.cell-selected {
        stroke: var(--secondary-dark)!important;
      }

      &:hover {
        stroke: var(--secondary-dark)!important;
      }
    }
  }

  &__info-text {
    padding: 15px 0;
    font-size: 16px;
    color: #787878;
    line-height: 1.5;
    white-space: pre-line;
  }

  .hm-row {
    &_active {
      color: var(--secondary-dark);
    }
  }

  .hm-col {
    &_active {
      color: var(--secondary-dark);
    }
  }

  &__spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  .bar-chart-controls {
    margin-left: auto;
    padding: 10px 30px;
  }
};

.goback-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4f4f4;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    margin-left: 3px;
    border-top: 2px solid #787878;
    border-left: 2px solid #787878;
    transform: rotate(-45deg);
  }
}

.heatmap-range {
  width: 540px;
}
