.custom-relation {
  width: 700px;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  &__body {
    display: flex;
  }

  &__block {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 25px;
  }

  &__label {
    display: block;
    font-size: 15px;
    padding-left: 5px;
    padding-bottom: 5px;
    opacity: 0.8;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__input {
    height: 36px;
  }

  &__button {
    margin-left: 5px;
    margin-right: 5px;
    min-width: 130px;
    display: block;
    justify-content: center;

    &_dark.button {
      color: #fff;
      background-color: var(--primary-light);
    }
  }
}