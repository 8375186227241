.semantic-categories {
  padding: 10px;

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: var(--background-light-color);
    overflow: hidden;
    padding-left: 10px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0px;
    }

    .circle-btn {
      width: 26px;
      height: 26px;
      background-color: var(--main-border-color);
      margin-right: 5px;
      color: var(--primary-medium);
    }
  }

  &__text {
    color: var(--primary-dark);
    font-size: 14px;
  }

  &__color {
    width: 5px;
    height: 100%;
    background-color: var(--dark-gray);
    flex-shrink: 0;
  }

  &__shape {
    padding-right: 5px;
    fill: currentColor;
    stroke: currentColor;
  }

  &__handle {
    cursor: grab;
    width: 20px;
    height: 20px;
    padding-right: 5px;
    line-height: 4px;
    text-align: center;
    color: var(--dark-gray);
  }

  &__right-block {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
