@import './variables.scss';
@import './fonts.scss';
@import './base.scss';
@import './common.scss';

.form {
  &__input {
    background-color: transparent;
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    color: #000000;
    padding-left: 10px;
    padding-right: 10px;

    &_small {
      height: 30px;
    }

    &_light {
      border-color: transparent;
    }

    &::placeholder {
      color: var(--dark-gray);
    }
  }

  &__input-mock {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    background-color: var(--main-gray);
    animation: pulse 1.2s infinite ease-in-out;
  }

  input[type=number]::-webkit-inner-spin-button {
    display: none;
  }
}

.table-wrap {
  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    min-height: 64px;

    &_filter {
      justify-content: space-between;

      .table-wrap__to-csv {
        position: unset;
      }
    }
  }

  &__to-csv {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
  }
}

.table-filter {
  margin-top: 16px;
  margin-bottom: 16px;

  &__input {
    height: 32px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    margin: 0;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid var(--main-border-color);
  }
}

.table {
  &__btn_export {
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid var(--main-border-color);
    padding: 0;
    width: 160px;
    height: 32px;
    border-radius: 4px;
    font-size: 15px;
    margin-top: 16px;
    margin-bottom: 16px;

    .text-wrap {
      display: inline-block;
      transition: transform 0.3s ease-in;
    }

    .export-spinner {
      position: absolute!important;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &_loading {
      .text-wrap {
        transform: translateX(-10px);
      }
    }
  }
}

.spinner-wrapper {
  display: flex;
  align-items: center;
}

@keyframes pulse {
  0% {
     background-color: rgba(169, 169, 169, 0.1)
   }
   50% {
      background-color: rgba(169, 169, 169, 0.3)
   }
   100% {
      background-color: rgba(169, 169, 169, 0.1)
   }
}

.title-border {
  text-align: center;
  overflow: hidden;

  span {
    position: relative;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    color: var(--light-dark-gray);
    padding-left: 5px;
    padding-right: 5px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 0.5px);
      width: 1000px;
      height: 1px;
      background-color: var(--main-border-color)
    }

    &::before {
      right: 100%;
    }
  
    &::after {
      left: 100%;
    }
  }
}

.mandatory-span {
  color: red;
  font-size: 14px;
}
