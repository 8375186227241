.complex-set-settings-root {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0 , 0.5);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.complex-set-settings-content {
  background-color: #FFFFFF;
  padding: 30px;
  color: #000000;
  position: relative;
  max-height: 80vh;
  max-width: 80vw;
  min-width: 1200px;
}

.overflow-container {
  max-height: 75vh;
  max-width: 80vw;
  overflow-y: auto;
}

.complex-set-settings-content .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.complex-set-settings-content .header {
  flex-basis: 15%;
  padding: 15px;
  border: 1px solid #e0e0e0;
}

.complex-set-settings-content .main {
  flex-basis: 75%;
  max-height: 90vh;
}

.complex-set-settings-content .footer {
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;
}


.main .settings-table {
  flex-basis: 60%;
}

.settings-table .settings-table-header {
  flex-basis: 15%;
  display: flex;
  justify-content: space-between;
}

.settings-table .settings-table-header div {
  text-align: center;
}

.settings-table .settings-table-body {
  flex-basis: 85%;
}

.settings-table .settings-table-body .row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin: 0px;
  padding: 10px;
}

.main .file-content {
  flex-basis: 40%;
  color: black;
}

.tableCell {
  flex-basis: 25%;
  text-align: center;
}

.tableCell select {
  max-width: 155px;
}

.file-content .file-content-header {
  font-weight:bold;
  font-size: 20px;
  margin: 10px 0px;
}

.file-content-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  overflow-x: auto;
  padding-bottom: 20px;
}

.file-content-column {
  display: flex;
  flex-direction: column;
  min-width: 130px;
}

.column-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  min-height: 19px;
}

.column-header {
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.file-content-row:first-of-type {
  margin-bottom: 10px;
}

.complex-set-button {
  padding: 5px 15px;
  border-radius: 5px;
}

.complex-set-button+.complex-set-button {
  margin-left: 20px;
}

.complex-set-settings-content .button-section {
  text-align: center;
  margin-top: 10px;
}

.complex-set-settings-bold {
  font-weight: bold;
}

.complex-set-settings-top-fields-wrap {
  margin-right: 15px;
}

.nested-modal-main-text {
  padding: 5px;
}

.nested-modal-button-section {
  margin-top: 30px;
}

.nested-modal-content {
  text-align: center;
  padding: 10px;
}
