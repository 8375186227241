.therapeutic-candidates__title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-light);
}

.therapeutic-candidates__chapter {
  position: relative;
  margin: 20px;
  padding-bottom: 20px;
}

.therapeutic-candidates__no-data {
  color: var(--dark-gray);
}

.indications-popup {
  display: flex;
  flex-direction: column;
  width: 70vw;
  max-height: 70vh;
  min-height: 150px;
  padding-bottom: 40px;
}

.indications-popup__title {
  height: 40px;
  line-height: 40px;
  color: var(--dark-gray);
  font-weight: bold;
  text-align: center;
  background-color: var(--main-gray);
}

.indications-popup__wrap {
  position: relative;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  column-count: 3;
  overflow-y: auto;
}

.indications-popup:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 40px;
  left: 0;
  height: 22px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.indications-popup__item {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.chemical-item {
  padding-bottom: 15px;
}

.chemical-item__title {
  display: block;
  padding-bottom: 10px;
  color: var(--primary-medium);
}
