.save-as-set {
  &_page {
    padding-top: 60px;
  }

  &_popup {
    width: 800px;
    max-height: 90vh;

    .save-as-set__title {
      padding-top: 10px;
    }

    .container {
      max-width: 800px;
    }
  }

  &__title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}

.ambiguous-concepts {
  margin-bottom: 50px;
}

@media only screen and (max-width: 800px) {
  .save-as-set_popup {
    width: 600px;

    .container {
      max-width: 600px;
    }
  }
}