.pub-details {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;

  &__wrap {
    padding-top: 40px;
  }

  &__title {
    display: block;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 1.5;
    color: var(--primary-medium);
    padding-bottom: 15px;
    text-decoration: none;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding-bottom: 10px;
    position: relative;
  }

  &__content {
    padding-bottom: 30px;
  }

  &__concepts {
    position: relative;
    display: flex;
  }

  &__triples {
    .pub-details__subtitle {
      padding-bottom: 0;
    }
  }

  &__big-container {
    display: flex;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 1625px;
  }

  &__text-wrapper {
    width: 100%;
    max-width: 1100px;
  }
}

.pub-details-info {
  padding-right: 10px;
  padding-bottom: 10px;
  line-height: 1.3;

  &__title {
    font-weight: 600;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__block {
    padding-bottom: 3px;
  }

  &__column {
    width: 49%;
  }
  
  &__show-block {
    display: block;
    font-style: italic;
    color: var(--dark-gray);
    cursor: pointer;
  }

  &__management-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}

.pub-details-article {
  &__content {
    line-height: 1.5;
  }
}

.pub-details__sidebar {
  padding: 0;
  padding-top: 5px;
  width: 240px;
  margin-right: 15px;

  &-title {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .colors__item {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: var(--background-light-color);
    overflow: hidden;
    padding-left: 10px;
    margin-bottom: 5px;
  }

  .sem-cat__color {
    width: 5px;
    height: 100%;
    margin-left: auto;
    flex-shrink: 0;
    background-color: var(--dark-gray);
  }

  .sem-cat__text {
    color: var(--primary-dark);
    font-size: 14px;
  }
}

mark {
  border-radius: 4px;
}

.pub-details-highlight {
  display: inline-block;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.pub-details-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.genes_molecular_sequences {
  background-color: rgba(218,108,0,0.4);
}

.disorders {
  background-color: rgba(107,0,0,0.4);
}

.phenomena {
  background-color: rgba(182,219,255,0.4);
}

.chemicals_drugs {
  background-color: rgba(204,204,204,0.4);
}

.occupations {
  background-color: rgba(109,182,255,0.4);
}

.concepts_ideas {
  background-color: rgba(255,109,182,0.4);
}

.physiology {
  background-color: rgba(0,145,145,0.4);
}

.anatomy {
  background-color: rgba(254,254,108,0.4);
}

.geographic_areas {
  background-color: rgba(182,109,255,0.4);
}

.organizations {
  background-color: rgba(145,72,0,0.4);
}

.living_beings {
  background-color: rgba(72,0,145,0.4);
}

.objects {
  background-color: rgba(0,109,219,0.4);
}
