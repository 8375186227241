.boxplot-chart {
  width: 100%;
  overflow-x: auto;
}

.boxplot-chart--hpa .boxplot-chart-container {
  position: relative;
}

.boxplot-chart__tooltip {
  position: absolute;
  z-index: 10;
  background-color: #FFFFFF;
  font-size: 14px;
  padding: 4px;
  border: 1px solid #000000;
  border-radius: 3px;
  letter-spacing: 0.3px;
}

.boxplot-chart__tooltip span {
  display: block;
}

.boxplot-chart__axis_x .tick text {
  font-size: 16px;
  letter-spacing: 0.3px;
  cursor: pointer;
}

.tool-tip-title {
  margin-top: 0px;
  text-transform: capitalize;
}

.chart-tooltip-boxplot {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  pointer-events: none;
  max-width: 300px;
  z-index: 10;
}

.chart-tooltip-boxplot__content {
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

.boxplot-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__radio {
    display: flex;
    align-items: center;
  }

  &__text {
    padding-right: 10px;
  }
}
