// Management pages
.management-controls {
  margin-left: auto;
  margin-right: 15px;

  &__item {
    display: inline-block;
    margin: 0 2px;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    color: var(--dark-gray);
  }
}

.management__create-button {
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.management-buttons-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.management-container__buttons {
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 20px;
}

.management-buttons {
  height: 100%;
  margin-left: auto;
}

.management-buttons__button {
  &:last-child {
    margin-right: 0px;
  }

  &[disabled] {
    &:hover,
    &:hover i,
    &:hover svg {
      cursor: not-allowed;
    }
  }

  svg, i {
    margin-right: 8px;
  }

  .heatmap-icon polygon,
  .heatmap-icon g {
    fill: #787878;
  }

  &:not([disabled]) {
    svg,
    .heatmap-icon polygon,
    .heatmap-icon g {
      fill: var(--primary-medium);
    }
  }
}
