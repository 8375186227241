.cell-line-selection-wrap {
  margin-bottom: 30px;
}

.cell-line-selection-form {
  display: flex;
  align-items: center;
  justify-content: center;

  &__select-gene {
    max-width: 400px;
  }

  &__select-set-block {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  &__select-set-input {
    width: 620px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--main-gray);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__text {
    padding-bottom: 20px;
  }

  &__button {
    margin-left: 20px;
  }
}

.cell-line-selection-title .workflows__title {
   text-align: center;
}

.cell-line-selection-block {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  //flex-wrap: wrap;

/*  &__col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
  }*/

  &__cell-selection {
    width: 70%;
    margin-left: 15px;
    padding: 0 15px;
    max-height: 500px;
    overflow: scroll;
  }

  &__leftbuttons {
    //width: 30%;
    max-width: 250px;
    padding-top: 30px;
    margin-left: 20%;
  }

  &__accordion {
    margin-bottom: 10px;
  }

  &__sticky_header {
    width: 70%;
    position: sticky;
    margin-left: 30px;
    display: grid;
  }

  &__title {
    margin-top: 0px;
    margin-bottom: 10px;
    grid-column: 2;
  }

  &__select_btn {
    grid-column: 1;
    margin-left: 10px;
    margin-top: 5px;
  }

  &__list {
    list-style: none;
    margin: 10px 15px;
    padding: 0;
  }

  &__item {
    width: 100%;
    padding: 5px;
    margin-bottom: 8px;
    background-color: var(--background-light-color);
    border-radius: var(--border-radius);
    cursor: pointer;

    &--selected {
      font-weight: bold;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
  }

  &__sub-title {
    color: var(--primary-medium);
    font-weight: bold;
  }

  &__buttons-wrapper {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

  &__btn {
    margin-bottom: 15px;
    width: 180px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  &__select-btn {
    margin: 5px;
    width: 120px;
    max-height: 40px;
    //margin-top: 17px;
    padding-left: 5px;
    padding-right: 5px;

    &:first-of-type {
      margin-left: 20px;
    }
  }

  &__search-btn {
    min-width: 180px;
    margin: 15px auto 0 auto;
  }
}

.cell-line-configuration-table__icon {
  cursor: pointer;
}

.results-data-block {
  .scrollable-x-table {
    overflow: initial;
  }
}

.accordion-item__title {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cell-line-selection__no-data {
  margin: 0 auto;
  color: red;
}
